import { ApiCredentials } from "./api_credentials";

export const storeCredentials = (credentials: ApiCredentials) => {
  window.localStorage.setItem("mportal-auth", JSON.stringify(credentials));
};

export const getCredentials = (): ApiCredentials | null => {
  const data = window.localStorage.getItem("mportal-auth");
  if (!data) {
    return null;
  }

  const credentials = JSON.parse(data) as ApiCredentials;
  return credentials;
};

export const deleteCredentials = () => {
  window.localStorage.removeItem("mportal-auth");
};
