import { useNavigate } from "react-router-dom";

import { FindPatientComponent } from "./find_patient";
import { PageContainer } from "../common/page_container";
import { PageTitle } from "../common/page_title";

export const PatientsSearch = () => {
  const navigate = useNavigate();

  return (
    <PageContainer>
      <PageTitle>Patient Search</PageTitle>
      <FindPatientComponent
        selectedPatientChanged={(patientRecord) => {
          if (patientRecord) {
            navigate(`/patients/${patientRecord.recordno}/overview`);
          }
        }}
      />
    </PageContainer>
  );
};
