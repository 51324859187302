import * as React from "react";
import { AxiosError } from "axios";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { Link, useNavigate } from "react-router-dom";

import * as rhf from "react-hook-form";
import * as gc from "../state/global_context";
import { UserData, CreateUserRequest } from "../users/api_types";
import { PageContainer } from "../common/page_container";
import { PageTitle } from "../common/page_title";

interface NewUserFormData {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  isStaff: boolean;
  isActive: boolean;
  isSuperuser: boolean;
  password: string;
  passwordVerify: string;
}

const NewUserForm = ({
  setErrorMessage,
}: {
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [globalState] = gc.useGlobalContext();
  const form = rhf.useForm<NewUserFormData>();
  const navigate = useNavigate();
  const [matching, setMatching] = React.useState(false);

  const onSubmit = form.handleSubmit(async (data: NewUserFormData) => {
    const reqData: CreateUserRequest = {
      ...data,
    };

    try {
      const res = await globalState.mportalEndpoint.post<UserData>(
        `users/`,
        reqData
      );
      form.reset(res.data);
      setErrorMessage("");
      navigate(`/users`);
    } catch (error) {
      setErrorMessage(
        error instanceof AxiosError
          ? "Error: " + error.response?.data.toString()
          : "Error"
      );
    }
  });
  return (
    <Box>
      <form onSubmit={onSubmit}>
        <Box sx={{ maxWidth: 200 }}>
          <Stack spacing={1}>
            <rhf.Controller
              control={form.control}
              name="username"
              defaultValue=""
              shouldUnregister={true}
              render={({ field, fieldState }) => (
                <TextField
                  label="Username"
                  {...field}
                  error={!!fieldState.error}
                  fullWidth
                  inputProps={{ maxLength: 30, minLength: 2 }}
                />
              )}
            />
            <rhf.Controller
              control={form.control}
              name="firstName"
              defaultValue=""
              shouldUnregister={true}
              render={({ field, fieldState }) => (
                <TextField
                  label="Firstname"
                  {...field}
                  error={!!fieldState.error}
                  fullWidth
                  inputProps={{ maxLength: 30 }}
                />
              )}
            />
            <rhf.Controller
              control={form.control}
              name="lastName"
              defaultValue=""
              shouldUnregister={true}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Lastname"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  fullWidth
                  inputProps={{ maxLength: 30 }}
                />
              )}
            />
            <rhf.Controller
              control={form.control}
              name="email"
              defaultValue=""
              shouldUnregister={true}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="email"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  fullWidth
                  inputProps={{ maxLength: 30 }}
                />
              )}
            />
            <rhf.Controller
              control={form.control}
              name="isStaff"
              defaultValue={true}
              shouldUnregister={true}
              render={({ field }) => (
                <FormControlLabel
                  label="Staff?"
                  labelPlacement="start"
                  control={<Checkbox defaultChecked={true} />}
                  {...field}
                />
              )}
            />
            <rhf.Controller
              control={form.control}
              name="isActive"
              defaultValue={true}
              shouldUnregister={true}
              render={({ field }) => (
                <FormControlLabel
                  label="Active?"
                  labelPlacement="start"
                  control={<Checkbox defaultChecked={true} />}
                  {...field}
                />
              )}
            />
            <rhf.Controller
              control={form.control}
              name="isSuperuser"
              defaultValue={false}
              shouldUnregister={true}
              render={({ field }) => (
                <FormControlLabel
                  label="Superuser?"
                  labelPlacement="start"
                  control={<Checkbox defaultChecked={false} />}
                  {...field}
                />
              )}
            />
            <rhf.Controller
              control={form.control}
              name="password"
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="New password"
                  value={field.value}
                  onChange={(event) => {
                    setMatching(
                      event.target.value === form.getValues("passwordVerify")
                    );
                    field.onChange(event);
                  }}
                  error={!!error}
                  type="password"
                  inputProps={{ minLength: 8 }}
                />
              )}
              rules={{ required: "password required" }}
            />
            <rhf.Controller
              control={form.control}
              name="passwordVerify"
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="New password (confirm)"
                  value={field.value}
                  onChange={(event) => {
                    setMatching(
                      event.target.value === form.getValues("password")
                    );
                    field.onChange(event);
                  }}
                  error={!!error}
                  type="password"
                  inputProps={{ minLength: 8 }}
                />
              )}
              rules={{ required: "New password (confirm) required" }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!form.formState.isDirty || !matching}
            >
              Save
            </Button>
          </Stack>
        </Box>
      </form>
    </Box>
  );
};

export const NewUser = () => {
  const [errorMessage, setErrorMessage] = React.useState<string>("");

  return (
    <PageContainer>
      <PageTitle>
        <Link to="/users">Users</Link> / New User
      </PageTitle>
      <Paper sx={{ p: 2 }}>
        {errorMessage.length === 0 ? (
          ""
        ) : (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        <NewUserForm setErrorMessage={setErrorMessage} />
      </Paper>
    </PageContainer>
  );
};
