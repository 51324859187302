import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
//import Card from "@mui/material/Card";
//import CardContent from "@mui/material/CardContent";
//import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
//import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
//import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
//import Table from "@mui/material/Table";
//import TableBody from "@mui/material/TableBody";
//import TableContainer from "@mui/material/TableContainer";
//import TableRow from "@mui/material/TableRow";
//import TableCell from "@mui/material/TableCell";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditIcon from "@mui/icons-material/Edit";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useNavigate } from "react-router-dom";
//import * as datefns from "date-fns";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { PageContainer } from "../common/page_container";
import { PageTitle } from "../common/page_title";
import * as gc from "../state/global_context";
import { UserData } from "../users/api_types";

type ActiveUserFilter = "activeOnly" | "inactiveOnly" | "all";

const UsersDataGrid = ({
  users,
  activeUserFilter,
}: {
  users: UserData[];
  activeUserFilter: ActiveUserFilter;
}) => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "username", headerName: "Username", width: 120 },
    {
      field: "firstName",
      headerName: "First",
      width: 120,
    },
    {
      field: "lastName",
      headerName: "Last",
      width: 120,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
    },
    {
      field: "isStaff",
      headerName: "Staff?",
      width: 75,
      type: "boolean",
    },
    {
      field: "isActive",
      headerName: "Active?",
      width: 75,
      type: "boolean",
    },
    {
      field: "isSuperuser",
      headerName: "Superuser?",
      width: 75,
      type: "boolean",
    },
    {
      field: "lastLogin",
      headerName: "Last login",
      width: 150,
    },
    {
      field: "dateJoined",
      headerName: "Date added",
      width: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params: GridRenderCellParams<UserData>) => {
        return (
          <span>
            <Tooltip title="Edit">
              <IconButton onClick={() => navigate(`/users/${params.id}/edit`)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </span>
        );
      },
    },
  ];

  const filterMap = {
    all: () => true,
    activeOnly: (user: UserData) => user.isActive,
    inactiveOnly: (user: UserData) => !user.isActive,
  };

  const rows = users.filter(filterMap[activeUserFilter]);

  return (
    <Container sx={{ minHeight: 600, height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        sx={{ marginTop: 2 }}
      />
    </Container>
  );
};

export const ManageUsers = () => {
  const [allUsers, setAllUsers] = React.useState<UserData[]>([]);
  const [globalState] = gc.useGlobalContext();

  const [activeFilter, setActiveFilter] =
    React.useState<ActiveUserFilter>("activeOnly");
  const navigate = useNavigate();

  const getUsers = async () => {
    const res = await globalState.mportalEndpoint.get<UserData[]>(`users/`);
    setAllUsers(res.data);
  };

  React.useEffect(() => {
    getUsers();
  }, []);

  if (allUsers.length === 0) {
    return (
      <Container maxWidth="xl" sx={{ p: 2 }}>
        <p>Loading...</p>
      </Container>
    );
  }

  return (
    <PageContainer>
      <PageTitle>Users</PageTitle>
      <Container sx={{ width: "90%" }}>
        <Stack direction="row">
          <Stack direction="row">
            <Typography variant="h6" sx={{ marginRight: 2 }}>
              Filters
            </Typography>
            <ToggleButtonGroup
              value={activeFilter}
              exclusive
              onChange={(event, newValue) => setActiveFilter(newValue)}
            >
              <ToggleButton value="activeOnly">Active</ToggleButton>
              <ToggleButton value="inactiveOnly">Inactive</ToggleButton>
              <ToggleButton value="all">All</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            variant="contained"
            color="primary"
            startIcon={<PersonAddIcon />}
            onClick={() => navigate("/users/new-user")}
          >
            New User
          </Button>
        </Stack>
      </Container>
      <UsersDataGrid users={allUsers} activeUserFilter={activeFilter} />
    </PageContainer>
  );
};
