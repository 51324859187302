import * as React from "react";
import Alert from "@mui/material/Alert";
import { AxiosError } from "axios";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import FormGroup from "@mui/material/FormGroup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import * as datefns from "date-fns";
import * as rhf from "react-hook-form";

import { PageContainer } from "../common/page_container";
import { PageTitle } from "../common/page_title";
import { PatientFileTemplate, NewPatientFileTemplate } from "./api_types";
import * as gc from "../state/global_context";

interface InfoMessage {
  severity: "success" | "error";
  message: string;
}

interface NewPatientFileTemplateFormData {
  name: string;
  summary: string;
  notes: string;
}

export const NewPatientFileTemplateComponent = () => {
  const [template, setTemplate] = React.useState<NewPatientFileTemplate>(
    {
      name: "TODO",
      summary: "TODO",
      notes: "TODO"
    }
  );
  const [globalState] = gc.useGlobalContext();
  const [errorMessage, setErrorMessage] = React.useState<InfoMessage>({
    severity: "success",
    message: "",
  });
  const form = rhf.useForm<NewPatientFileTemplateFormData>();
  const navigate = useNavigate();

  const onSubmit = form.handleSubmit(
    async (data: NewPatientFileTemplateFormData) => {
      const rdata: NewPatientFileTemplate = {
        name: data.name,
        summary: data.summary,
        notes: data.notes,
      };

      try {
        await globalState.mportalEndpoint.post<NewPatientFileTemplate>(
          `office/patient-file-templates`,
          rdata
        );
        setErrorMessage({ severity: "success", message: "New template created" });
        navigate('/admin/patient-file-categories');
      } catch (error) {
        setErrorMessage({
          severity: "error",
          message:
            error instanceof AxiosError
              ? "Error: " + error.response?.data.toString()
              : "Error",
        });
      }
    }
  );

  return (
    <PageContainer>
      <PageTitle>
          <NavLink to={`/admin/patient-file-categories`}>
            Manage Patient File Templates and Categories
          </NavLink>{" "} /
      New Patient File Template</PageTitle>

      <Paper
        sx={{ marginTop: 1, minHeight: 100, p: 1, fontFamily: "Monospace" }}
      >
        {errorMessage.message.length === 0 ? (
          ""
        ) : (
          <Alert severity={errorMessage.severity} sx={{ marginBottom: 2 }}>
            {errorMessage.message}
          </Alert>
        )}

        <form onSubmit={onSubmit}>
          <Stack spacing={1}>
            <rhf.Controller
              control={form.control}
              name="name"
              defaultValue={template.name}
              shouldUnregister={true}
              render={({ field, fieldState }) => (
                <TextField
                  label="Name"
                  sx={{ maxWidth: 400 }}
                  {...field}
                  error={!!fieldState.error}
                />
              )}
            />

            <rhf.Controller
              control={form.control}
              name="summary"
              defaultValue={template.summary}
              shouldUnregister={true}
              render={({ field, fieldState }) => (
                <TextField
                  label="Summary"
                  sx={{ maxWidth: 400 }}
                  {...field}
                  error={!!fieldState.error}
                />
              )}
            />

            <rhf.Controller
              control={form.control}
              name="notes"
              defaultValue={template.notes}
              shouldUnregister={true}
              render={({ field, fieldState }) => (
                <TextField
                  label="Note"
                  multiline
                  sx={{ minHeight: '50%' }}
                  {...field}
                  error={!!fieldState.error}
                  fullWidth
                />
              )}
            />
            <Button
              sx={{ marginTop: 2, maxWidth: 200 }}
              type="submit"
              variant="contained"
              color="primary"
              disabled={!form.formState.isDirty}
            >
              Save
            </Button>
          </Stack>
        </form>
      </Paper>
    </PageContainer>
  );
};
