import React from "react";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

import * as gc from "../state/global_context";

const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = React.useRef<() => void>(callback);

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const tick = () => savedCallback.current();
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

interface BackendVersionResponse {
  buildMode: string;
  buildId: string;
}

export const UpdateChecker = () => {
  const [globalState] = gc.useGlobalContext();
  const [backendVersion, setBackendVersion] =
    React.useState<BackendVersionResponse | null>(null);

  const frontendBuildMode = process.env.REACT_APP_MPORTAL_BUILD_MODE;
  const frontendBuildId: string = process.env.REACT_APP_MPORTAL_BUILD_ID ?? "0";

  const checkForUpdatesIntervalMilliseconds = 120000;

  const checkForUpdates = async () => {
    const res = await globalState.mportalEndpoint.get<BackendVersionResponse>(
      "system/version"
    );

    if (
      !backendVersion ||
      res.data.buildMode !== backendVersion.buildMode ||
      res.data.buildId !== backendVersion.buildId
    ) {
      setBackendVersion(res.data);
    }
  };

  useInterval(checkForUpdates, checkForUpdatesIntervalMilliseconds);

  //  React.useEffect(() => {
  //    const interval = setInterval(async () => , checkForUpdatesIntervalMilliseconds);
  //    return () => clearInterval(interval);
  //  }, []);

  const updateAvailable =
    backendVersion &&
    frontendBuildMode === backendVersion.buildMode &&
    frontendBuildId !== backendVersion.buildId;

  if (!updateAvailable || !backendVersion) {
    return <div />;
  }

  return (
    <Container sx={{ m: 1 }}>
      <Alert
        action={
          <Button
            color="inherit"
            variant="outlined"
            size="small"
            onClick={() => window.location.reload()}
          >
            Reload
          </Button>
        }
        severity="info"
      >
        Application update available ({frontendBuildId} -&gt;{" "}
        {backendVersion.buildId}). Please reload the page.
      </Alert>
    </Container>
  );
};
