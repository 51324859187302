import * as React from "react";
import Alert from "@mui/material/Alert";
import { AxiosError } from "axios";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "@mui/material/Dialog";
import EditIcon from "@mui/icons-material/Edit";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import { PageContainer } from "../common/page_container";
import { PageTitle } from "../common/page_title";
import {
  PatientFileTemplate,
  ValidPatientFileTemplateCategories,
} from "./api_types";
import {
  usePatientFileTemplates,
  usePatientFileTemplateCategories,
} from "./hooks";
import * as rhf from "react-hook-form";
import * as gc from "../state/global_context";

interface InfoMessage {
  severity: "success" | "error";
  message: string;
}

interface EditFileCategoriesFormData {
  text: string;
}

export const EditFileCategories = () => {
  const [globalState] = gc.useGlobalContext();
  const [errorMessage, setErrorMessage] = React.useState<InfoMessage>({
    severity: "success",
    message: "",
  });
  const form = rhf.useForm<EditFileCategoriesFormData>();

  const [categories, setCategories] =
    React.useState<ValidPatientFileTemplateCategories | null>(null);
  const getCategories = async () => {
    const res =
      await globalState.mportalEndpoint.get<ValidPatientFileTemplateCategories>(
        `office/patient-file-templates/categories`
      );
    setCategories(res.data);
  };
  React.useEffect(() => {
    getCategories();
  }, []);

  if (categories === null) {
    return (
      <Container maxWidth="xl" sx={{ p: 2 }}>
        <p>Loading...</p>
      </Container>
    );
  }

  const catText =
    "# One category per line.\n" +
    "# Empty lines, and lines starting with '#' are ignored\n" +
    "\n" +
    categories.categories.join("\n");

  const onSubmit = form.handleSubmit(
    async (data: EditFileCategoriesFormData) => {
      const newCategories = data.text
        .split("\n")
        .map((line) => line.trim())
        .filter((line) => !line.startsWith("#") && line.length > 0);

      const update: ValidPatientFileTemplateCategories = {
        categories: newCategories,
      };

      try {
        await globalState.mportalEndpoint.put<ValidPatientFileTemplateCategories>(
          `office/patient-file-templates/categories`,
          update
        );
        setErrorMessage({ severity: "success", message: "Categories updated" });
      } catch (error) {
        setErrorMessage({
          severity: "error",
          message:
            error instanceof AxiosError
              ? "Error: " + error.response?.data.toString()
              : "Error",
        });
      }
    }
  );

  return (
    <Paper sx={{ p: 2 }}>
      {errorMessage.message.length === 0 ? (
        ""
      ) : (
        <Alert severity={errorMessage.severity} sx={{ marginBottom: 2 }}>
          {errorMessage.message}
        </Alert>
      )}
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Categories
      </Typography>
      <form onSubmit={onSubmit}>
        <rhf.Controller
          control={form.control}
          name="text"
          defaultValue={catText}
          shouldUnregister={true}
          render={({ field, fieldState }) => (
            <TextField
              label="Categories"
              multiline
              {...field}
              error={!!fieldState.error}
              fullWidth
            />
          )}
        />
        <Button
          sx={{ marginTop: 2 }}
          type="submit"
          variant="contained"
          color="primary"
          disabled={!form.formState.isDirty}
        >
          Save
        </Button>
      </form>
    </Paper>
  );
};

const DeleteTemplateConfirmationDialog = ({
  open,
  setOpen,
  onConfirmDelete,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
  onConfirmDelete: () => void;
}) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <DialogContentText>Delete?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            onConfirmDelete();
            setOpen(false);
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ManageTemplates = () => {
  const navigate = useNavigate();
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    React.useState<boolean>(false);
  const [templates, setTemplates] = React.useState<
    PatientFileTemplate[] | null
  >(null);
  const [globalState] = gc.useGlobalContext();
  const [selectedTemplateId, setSelectedTemplateId] = React.useState(-1);
  const getTemplates = async () => {
    const res = await globalState.mportalEndpoint.get<PatientFileTemplate[]>(
      `office/patient-file-templates/`
    );
    setTemplates(res.data);
  };
  React.useEffect(() => {
    getTemplates();
  }, []);

  const handleDelete = async () => {
    if (selectedTemplateId == -1) {
      return;
    }
    await globalState.mportalEndpoint.delete(
      `office/patient-file-templates/${selectedTemplateId}`
    );
    await getTemplates();
  };

  if (templates === null) {
    return (
      <Paper sx={{ p: 2, marginBottom: 2 }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Templates
        </Typography>
        Loading...
      </Paper>
    );
  }

  return (
    <Paper sx={{ p: 2, marginBottom: 2 }}>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Templates
      </Typography>
      <Button
        type="submit"
        variant="contained"
        color="secondary"
        sx={{ marginBottom: 2 }}
        onClick={() => navigate("/admin/new-patient-file-template")}
      >
        New Template
      </Button>
      <DeleteTemplateConfirmationDialog
        open={showDeleteConfirmationDialog}
        setOpen={setShowDeleteConfirmationDialog}
        onConfirmDelete={handleDelete}
      />
      <List
        dense={true}
        sx={{
          maxHeight: 400,
          maxWidth: 800,
          overflow: "auto",
          border: 1,
          borderColor: "primary.light",
          marginBottom: 2,
        }}
      >
        {templates.map((pft: PatientFileTemplate) => (
          <ListItem
            key={pft.id}
            selected={selectedTemplateId === pft.id}
            onClick={() =>
              setSelectedTemplateId(pft.id)
            }
          >
            <ListItemText>{pft.name}</ListItemText>
            <Button
              variant="outlined"
              size="small"
              sx={{ marginLeft: 1 }}
              onClick={(event) =>
                navigate(`/admin/manage-patient-file-template/${pft.id}`)
              }
            >
              <EditIcon />
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ marginLeft: 1 }}
              onClick={(event) => {
                setSelectedTemplateId(pft.id);
                setShowDeleteConfirmationDialog(true);
              }}
            >
              <DeleteIcon />
            </Button>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export const ManagePatientFileCategoriesAndTemplates = () => {
  return (
    <PageContainer>
      <PageTitle>Manage Patient File Templates and Categories</PageTitle>
      <ManageTemplates />
      <EditFileCategories />
    </PageContainer>
  );
};
