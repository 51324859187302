import * as React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";

import { EncounterData } from "./api_types";
import { PatientRecord } from "../patients/api_types";
import { DeleteEncounterModal } from "./delete_encounter_modal";

interface EncounterDetailProps {
  encounter: EncounterData | null;
  patientRecord: PatientRecord | null;
  /**
   * EncounterDetail calls this after the user has edited the encounter and
   * changes have been commited.
   */
  onEncounterUpdated?: (encounter: EncounterData) => void;

  /**
   * EncounterDetail calls this after the user has deleted the encounter.
   */
  onEncounterDeleted: (encounterId: number) => void;
}
export const EncounterDetail = ({
  encounter,
  patientRecord,
  onEncounterDeleted,
}: EncounterDetailProps): JSX.Element => {
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);

  if (!encounter || !patientRecord) {
    return (
      <Card
        sx={{
          width: "100%",
          height: "30vh",
          p: 1,
        }}
      />
    );
  }

  const columns: GridColumns = [
    { field: "serviceCode", headerName: "svc code" },
    { field: "charge", headerName: "charge" },
    { field: "paid", headerName: "paid" },
    { field: "due", headerName: "due" },
    { field: "receivable", headerName: "receivable" },
    { field: "note", headerName: "note" },
  ];
  const rows: GridRowsProp = encounter.services.map((service) => ({
    ...service,
    id: service.serviceId,
  }));

  return (
    <Card
      sx={{
        width: "100%",
        p: 1,
      }}
    >
      <Typography variant="h6">
        {encounter.ptLastname}, {encounter.ptFirstname} ({encounter.patientId})
      </Typography>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            Encounter ID: {encounter.encounterId}
          </Grid>
          <Grid item xs={12} md={5}>
            Provider ID: {encounter.providerShortid}
          </Grid>
          <Grid item xs={12} md={5}>
            Date: {encounter.date}
          </Grid>
          <Grid item xs={12} md={5}>
            Time: {encounter.time}
          </Grid>
          <Grid item xs={12}>
            Note: {encounter.note}
          </Grid>
          <Grid item xs={12}>
            ICD: {encounter.icd}
          </Grid>
        </Grid>

        <div style={{ height: "25vh", width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            isRowSelectable={() => false}
            pageSize={10}
            rowsPerPageOptions={[10]}
            sx={{ mt: 2 }}
          />
        </div>
      </CardContent>
      <Box
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(`/encounters/edit/${encounter.encounterId}`)}
        >
          Edit Encounter
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setOpenDeleteModal(true)}
        >
          Delete Encounter
        </Button>
      </Box>

      <DeleteEncounterModal
        encounter={encounter}
        openModel={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        onEncounterDeleted={onEncounterDeleted}
      />
    </Card>
  );
};
