import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";

import { AxiosInstance } from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import * as rhf from "react-hook-form";
import * as datefns from "date-fns";

import {
  EncounterData,
  EncounterUpdate,
  NewServiceData,
} from "./api_types";
import { PageContainer } from "../common/page_container";
import { PageTitle } from "../common/page_title";
import { PatientRecord } from "../patients/api_types";
import * as gc from "../state/global_context";

interface EditEncounterFormData {
  code_99201: boolean;
  code_99202: boolean;
  code_99203: boolean;
  code_99204: boolean;
  code_99205: boolean;
  code_99211: boolean;
  code_99212: boolean;
  code_99213: boolean;
  code_99214: boolean;
  code_99215: boolean;
  code_EKG: boolean;
  code_B12: boolean;
  code_GMMEASL: boolean;
  code_SPIRO1: boolean;
  code_FLU: boolean;
  code_REPORT: boolean;
  code_SPIROBDI: boolean;
  code_PNEUMO: boolean;
  code_MMR: boolean;
  code_URINE: boolean;
  code_STOOL: boolean;
  code_BLOODSUG: boolean;
  code_PPD: boolean;
  code_TETANUS: boolean;
  code_NEWPHYS: boolean;
  code_ESTPHYS: boolean;
  code_MISC: boolean;
  miscNote: string;
  dateTime: Date;
  providerShortid: string;
  icd: string | null;
  note: string | null;
}

const encounterHasService = (
  encounter: EncounterData,
  code: string
): boolean => {
  return !!encounter.services.find((service) => service.serviceCode === code);
};

const encounterGetMiscServiceNote = (
  encounter: EncounterData
): string | null => {
  const service = encounter.services.find(
    (service) => service.serviceCode === "MISC"
  );
  return service ? service.note : null;
};

interface CustomControlledTextFieldProps {
  elemName: keyof EditEncounterFormData;
  defaultValue: string;
  label: string;
  encounter: EncounterData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: rhf.Control<EditEncounterFormData, any>;
  maxLength: number;
}

const ControlledTextField = (
  props: CustomControlledTextFieldProps
): JSX.Element => {
  return (
    <rhf.Controller
      control={props.control}
      name={props.elemName}
      defaultValue={props.defaultValue}
      shouldUnregister={true}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          label={props.label}
          onChange={onChange}
          value={value}
          error={!!error}
          fullWidth
          inputProps={{ maxLength: props.maxLength }}
        />
      )}
    />
  );
};

interface CustomControlledCheckboxProps {
  elemName: keyof EditEncounterFormData;
  code: string;
  label: string;
  encounter: EncounterData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: rhf.Control<EditEncounterFormData, any>;
}

const ControlledCheckbox = (
  props: CustomControlledCheckboxProps
): JSX.Element => {
  const defaultChecked = encounterHasService(props.encounter, props.code);
  return (
    <rhf.Controller
      control={props.control}
      name={props.elemName}
      shouldUnregister={true}
      defaultValue={defaultChecked}
      render={({ field }) => (
        <FormControlLabel
          control={<Checkbox defaultChecked={defaultChecked} />}
          label={props.label}
          {...field}
        />
      )}
    />
  );
};

const ControlledCheckboxGridItem = (
  props: CustomControlledCheckboxProps
): JSX.Element => {
  return (
    <Grid item md={2}>
      <ControlledCheckbox {...props} />
    </Grid>
  );
};

const updateEncounter = async (
  endpoint: AxiosInstance,
  encounter: EncounterData,
  update: EditEncounterFormData
) => {
  // Update the encounter object first
  // Never change: ptLastname, ptFirstname, patientId
  const edata: EncounterUpdate = {
    ptLastname: encounter.ptLastname,
    ptFirstname: encounter.ptFirstname,
    patientId: encounter.patientId,
    providerShortid: update.providerShortid,
    date: datefns.format(update.dateTime, "yyyy-MM-dd"),
    time: datefns.format(update.dateTime, "HH:mm:ss"),
    icd: update.icd,
    note: update.note,
  };
  await endpoint.put(`office/encounters/${encounter.encounterId}`, edata);

  // Remove services as needed
  for (const existingService of encounter.services) {
    const key =
      `code_${existingService.serviceCode}` as keyof EditEncounterFormData;
    console.log("checkinmg for service ", key);
    if (!update[key]) {
      console.log(`Removing service ${existingService.serviceCode}`);
      await endpoint.delete(`/office/services/${existingService.serviceId}`);
    }
  }

  // Add new services
  for (const elem of Object.keys(update)) {
    const keyname = elem as keyof EditEncounterFormData;
    if (keyname.startsWith("code_") && update[keyname]) {
      const code = keyname.split("_")[1];
      console.log(`Checking if encounter already has service ${code}`);
      if (!encounterHasService(encounter, code)) {
        console.log(`Adding service ${code}`);
        const newService: NewServiceData = {
          encounterId: encounter.encounterId,
          serviceCode: code,
          charge: "0.00",
          paid: "0.00",
          due: "0.00",
          receivable: "P",
          note: code === "MISC" ? update.miscNote : null,
        };

        await endpoint.post("/office/services", newService);
      }
    }
  }

  const getResponse = await endpoint.get<EncounterData>(
    `office/encounters/${encounter.encounterId}`
  );
  return getResponse.data;
};

type EditEncounterParams = {
  encounterId: string;
};

export const EditEncounter = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams<EditEncounterParams>();

  const { handleSubmit, control } = rhf.useForm<EditEncounterFormData>();

  const [encounter, setEncounter] = React.useState<EncounterData | null>(null);
  const [patientRecord, setPatientRecord] = React.useState<PatientRecord | null>(
    null
  );
  const [globalState] = gc.useGlobalContext();
  const endpoint = globalState.mportalEndpoint;
  const providers = globalState.providers;

  const dateTimeStr = encounter ? `${encounter.date} ${encounter.time}` : "";
  const encounterDateTime = dateTimeStr.length
    ? datefns.parse(dateTimeStr, "yyyy-MM-dd HH:mm:ss", new Date())
    : new Date();

  const loadData = async () => {
    const encounterUrl = `office/encounters/${params.encounterId}`;
    const encounterRes = await endpoint.get<EncounterData>(encounterUrl);
    setEncounter(encounterRes.data);

    const ptrecordUrl = `office/patients/${encounterRes.data.patientId}`;
    const ptrecordRes = await endpoint.get<PatientRecord>(ptrecordUrl);
    setPatientRecord(ptrecordRes.data);
  };

  React.useEffect(() => {
    loadData();
  }, []);

  const onSubmit = handleSubmit(async (data: EditEncounterFormData) => {
    if (encounter) {
      await updateEncounter(endpoint, encounter, data);
      navigate(-1);
    }
  });

  const cardStyle = {
    bgcolor: "background.paper",
    p: 2,
    m: 4,
  };

  return (
    <PageContainer>
      <PageTitle>
            Edit Encounter {params.encounterId}
      </PageTitle>
      <Card sx={cardStyle}>
        <form onSubmit={onSubmit}>
          <CardContent>
            {encounter === null || patientRecord === null ? (
              <Typography>Loading...</Typography>
            ) : (
              <Grid container spacing={1}>
                <Grid item md={3}>
                  <TextField
                    variant="standard"
                    InputProps={{ readOnly: true }}
                    label="Lastname"
                    defaultValue={encounter.ptLastname}
                  ></TextField>
                </Grid>
                <Grid item md={2} />
                <Grid item md={4}>
                  <rhf.Controller
                    control={control}
                    name="dateTime"
                    defaultValue={encounterDateTime}
                    shouldUnregister={true}
                    render={({ field }) => {
                      return (
                        <DesktopDateTimePicker
                          label="Date/Time"
                          {...field}
                          inputFormat="yyyy-MM-dd HH:mm"
                          mask="____-__-__ __:__"
                          renderInput={(params) => <TextField {...params} />}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={3} />
                <Grid item md={3}>
                  <TextField
                    variant="standard"
                    InputProps={{ readOnly: true }}
                    label="Firstname"
                    defaultValue={encounter.ptFirstname}
                  ></TextField>
                </Grid>
                <Grid item md={2}></Grid>
                <Grid item md={2}>
                  <ControlledTextField
                    elemName="icd"
                    label="ICD"
                    defaultValue={encounter.icd || ""}
                    control={control}
                    encounter={encounter}
                    maxLength={5}
                  />
                </Grid>
                <Grid item md={2}>
                  <rhf.Controller
                    control={control}
                    name="providerShortid"
                    defaultValue={encounter.providerShortid}
                    render={({ field }) => {
                      return (
                        <Select
                          variant="outlined"
                          label="Provider ID"
                          defaultValue={encounter.providerShortid}
                          {...field}
                        >
                          {providers.map((provider) => (
                            <MenuItem
                              key={provider.shortid}
                              value={provider.shortid}
                            >
                              {provider.shortid}
                            </MenuItem>
                          ))}
                          {providers.find(
                            (provider) => provider.shortid === "FD"
                          ) ? (
                            ""
                          ) : (
                            <MenuItem value="FD">FD</MenuItem>
                          )}
                        </Select>
                      );
                    }}
                  />
                </Grid>
                <Grid item md={2} />
                <Grid item md={2}>
                  <TextField
                    variant="standard"
                    InputProps={{ readOnly: true }}
                    label="Patient ID"
                    defaultValue={encounter.patientId}
                  ></TextField>
                </Grid>
                <Grid item md={2}>
                  <TextField
                    variant="standard"
                    InputProps={{ readOnly: true }}
                    label="NEWDX"
                    defaultValue={patientRecord ? patientRecord.newdx : "-"}
                  ></TextField>
                </Grid>
                <Grid item md={1} />
                <Grid item md={6}>
                  <ControlledTextField
                    elemName="note"
                    label="Note"
                    defaultValue={encounter.note || ""}
                    control={control}
                    encounter={encounter}
                    maxLength={255}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    variant="standard"
                    InputProps={{ readOnly: true }}
                    label="ICD2"
                    defaultValue={patientRecord ? patientRecord.icd2 : "-"}
                  ></TextField>
                </Grid>
                <Grid item md={3}>
                  <TextField
                    variant="standard"
                    InputProps={{ readOnly: true }}
                    label="ICD3"
                    defaultValue={patientRecord ? patientRecord.icd3 : "-"}
                  ></TextField>
                </Grid>
                <Grid item md={12} />
                <ControlledCheckboxGridItem
                  elemName="code_99201"
                  code="99201"
                  label="99201"
                  control={control}
                  encounter={encounter}
                />
                <ControlledCheckboxGridItem
                  elemName="code_99211"
                  code="99211"
                  label="99211"
                  control={control}
                  encounter={encounter}
                />
                <ControlledCheckboxGridItem
                  elemName="code_EKG"
                  code="EKG"
                  label="EKG"
                  control={control}
                  encounter={encounter}
                />
                <ControlledCheckboxGridItem
                  elemName="code_B12"
                  code="B12"
                  label="B12"
                  control={control}
                  encounter={encounter}
                />
                <ControlledCheckboxGridItem
                  elemName="code_GMMEASL"
                  code="GMMEASL"
                  label="m_gm_measl"
                  control={control}
                  encounter={encounter}
                />
                <Grid item md={1} />
                <ControlledCheckboxGridItem
                  elemName="code_99202"
                  code="99202"
                  label="99202"
                  control={control}
                  encounter={encounter}
                />
                <ControlledCheckboxGridItem
                  elemName="code_99212"
                  code="99212"
                  label="99212"
                  control={control}
                  encounter={encounter}
                />
                <ControlledCheckboxGridItem
                  elemName="code_SPIRO1"
                  code="SPIRO1"
                  label="spiro_1"
                  control={control}
                  encounter={encounter}
                />
                <ControlledCheckboxGridItem
                  elemName="code_FLU"
                  code="FLU"
                  label="flu"
                  control={control}
                  encounter={encounter}
                />
                <ControlledCheckboxGridItem
                  elemName="code_REPORT"
                  code="REPORT"
                  label="report"
                  control={control}
                  encounter={encounter}
                />
                <Grid item md={1} />
                <ControlledCheckboxGridItem
                  elemName="code_99203"
                  code="99203"
                  label="99203"
                  control={control}
                  encounter={encounter}
                />
                <ControlledCheckboxGridItem
                  elemName="code_99213"
                  code="99213"
                  label="99213"
                  control={control}
                  encounter={encounter}
                />
                <ControlledCheckboxGridItem
                  elemName="code_SPIROBDI"
                  code="SPIROBDI"
                  label="spiro_b_di"
                  control={control}
                  encounter={encounter}
                />
                <ControlledCheckboxGridItem
                  elemName="code_PNEUMO"
                  code="PNEUMO"
                  label="pneumovac"
                  control={control}
                  encounter={encounter}
                />
                <ControlledCheckboxGridItem
                  elemName="code_MMR"
                  code="MMR"
                  label="mmr"
                  control={control}
                  encounter={encounter}
                />
                <Grid item md={1} />
                <ControlledCheckboxGridItem
                  elemName="code_99204"
                  code="99204"
                  label="99204"
                  control={control}
                  encounter={encounter}
                />
                <ControlledCheckboxGridItem
                  elemName="code_99214"
                  code="99214"
                  label="99214"
                  control={control}
                  encounter={encounter}
                />
                <ControlledCheckboxGridItem
                  elemName="code_STOOL"
                  code="STOOL"
                  label="stool_ob"
                  control={control}
                  encounter={encounter}
                />
                <ControlledCheckboxGridItem
                  elemName="code_PPD"
                  code="PPD"
                  label="ppd"
                  control={control}
                  encounter={encounter}
                />
                <ControlledCheckboxGridItem
                  elemName="code_BLOODSUG"
                  code="BLOODSUG"
                  label="bloodsugar"
                  control={control}
                  encounter={encounter}
                />
                <Grid item md={1} />
                <ControlledCheckboxGridItem
                  elemName="code_99205"
                  code="99205"
                  label="99205"
                  control={control}
                  encounter={encounter}
                />
                <ControlledCheckboxGridItem
                  elemName="code_99215"
                  code="99215"
                  label="99215"
                  control={control}
                  encounter={encounter}
                />
                <ControlledCheckboxGridItem
                  elemName="code_URINE"
                  code="URINE"
                  label="urine"
                  control={control}
                  encounter={encounter}
                />
                <ControlledCheckboxGridItem
                  elemName="code_TETANUS"
                  code="TETANUS"
                  label="tetanus"
                  control={control}
                  encounter={encounter}
                />
                <Grid item md={3} />
                <Grid item md={3}>
                  <ControlledCheckbox
                    elemName="code_NEWPHYS"
                    code="NEWPHYS"
                    label="New Patient Physical"
                    control={control}
                    encounter={encounter}
                  />
                </Grid>
                <Grid item md={3}>
                  <ControlledCheckbox
                    elemName="code_ESTPHYS"
                    code="ESTPHYS"
                    label="Established Patient Physical"
                    control={control}
                    encounter={encounter}
                  />
                </Grid>
                <Grid item md={5}>
                  <ControlledCheckbox
                    elemName="code_MISC"
                    code="MISC"
                    label="Misc - specify"
                    control={control}
                    encounter={encounter}
                  />
                  <ControlledTextField
                    elemName="miscNote"
                    label=""
                    defaultValue={encounterGetMiscServiceNote(encounter) || ""}
                    control={control}
                    encounter={encounter}
                    maxLength={40}
                  />
                </Grid>
              </Grid>
            )}
          </CardContent>
          <CardActions>
            <Box
              m={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ m: 2 }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </Box>
          </CardActions>
        </form>
      </Card>
    </PageContainer>
  );
};
