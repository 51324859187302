import * as React from "react";
import * as gc from "../state/global_context";

import { ProviderData } from "./api_types";

export const ProvidersLoader = () => {
  const [globalState, globalStateDispatch] = gc.useGlobalContext();

  const getProviders = async () => {
    const res = await globalState.mportalEndpoint.get<ProviderData[]>(
      "office/providers",
      {
        params: {
          limit: 50,
          offset: 0,
        },
      }
    );
    globalStateDispatch({
      type: "SET_PROVIDERS",
      providers: res.data,
    });
  };

  React.useEffect(() => {
    getProviders();
  }, []);

  return <div />;
};
