import * as React from "react";

import * as gc from "../state/global_context";

import {
  PatientFileTemplate,
  ValidPatientFileTemplateCategories,
} from "./api_types";

export const usePatientFileTemplateCategories = () => {
  const [categories, setCategories] = React.useState<
    ValidPatientFileTemplateCategories | null
  >(null);
  const [globalState] = gc.useGlobalContext();
  const getCategories = async () => {
    const res = await globalState.mportalEndpoint.get<ValidPatientFileTemplateCategories>(
      `office/patient-file-templates/categories`
    );
    setCategories(res.data);
  };
  React.useEffect(() => {
    getCategories();
  }, []);
  return categories;
};

export const usePatientFileTemplates = () => {
  const [templates, setTemplates] = React.useState<
    PatientFileTemplate[] | null
  >(null);
  const [globalState] = gc.useGlobalContext();
  const getTemplates = async () => {
    const res = await globalState.mportalEndpoint.get<PatientFileTemplate[]>(
      `office/patient-file-templates/`
    );
    setTemplates(res.data);
  };
  React.useEffect(() => {
    getTemplates();
  }, []);
  return templates;
};

