import * as React from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";

import { useCurrentPatientContext } from "./current_patient_context";

interface PRFieldProps {
  label: string;
  value: string;
}

const PRTable = (props: { rows: PRFieldProps[]; maxWidth: number }) => (
  <Table size="small" sx={{ maxWidth: props.maxWidth }}>
    <TableBody>
      {props.rows.map((row) => (
        <TableRow key={row.label}>
          <TableCell>
            <Typography variant="body1">{row.label}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">{row.value}</Typography>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export const PatientOverview = () => {
  const patientRecord = useCurrentPatientContext();
  const navigate = useNavigate();

  if (!patientRecord) {
    return (
      <Container maxWidth="xl" sx={{ p: 2 }}>
        <p>Loading...</p>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="xl" sx={{ p: 1 }}>
        <Typography variant="h5">
          {patientRecord.lastname}, {patientRecord.firstname}{" "}
          {patientRecord.midname} (#{patientRecord.recordno})
        </Typography>
        <Container sx={{ p: 1, display: "flex" }}>
          <Box sx={{ flexGrow: 1, p: 1 }}>
            <Stack>
              <Paper sx={{ marginTop: 2, maxWidth: 700, p: 2 }}>
                <Typography variant="h6">Basic</Typography>
                <PRTable
                  maxWidth={400}
                  rows={[
                    { label: "Sex", value: patientRecord.sex },
                    { label: "DOB", value: patientRecord.dob },
                    { label: "MD", value: patientRecord.md },
                  ]}
                />

                <Stack sx={{ marginTop: 2 }}>
                  <Typography variant="body1">Diagnoses</Typography>
                  <ul>
                    {patientRecord.newdx && <li>{patientRecord.newdx}</li>}
                    {patientRecord.dx1 && <li>{patientRecord.dx1}</li>}
                    {patientRecord.dx2 && <li>{patientRecord.dx2}</li>}
                    {patientRecord.dx3 && <li>{patientRecord.dx3}</li>}
                  </ul>
                </Stack>
              </Paper>

              <Paper sx={{ marginTop: 2, maxWidth: 700, p: 2 }}>
                <Typography variant="h6">Contact</Typography>

                <PRTable
                  maxWidth={400}
                  rows={[
                    { label: "Address", value: patientRecord.address1 },
                    { label: "City", value: patientRecord.city },
                    { label: "State", value: patientRecord.state },
                    { label: "Zip", value: patientRecord.zip },
                    { label: "Phone", value: patientRecord.phone },
                    { label: "Facility", value: patientRecord.facility },
                  ]}
                />
              </Paper>

              <Paper sx={{ marginTop: 2, maxWidth: 700, p: 2 }}>
                <Typography variant="h6">Billing</Typography>

                <PRTable
                  maxWidth={600}
                  rows={[
                    { label: "INS / MC_CODE", value: patientRecord.mccode },
                    { label: "Medicare", value: patientRecord.medicare },
                    { label: "coinsure1", value: patientRecord.coinsure1 },
                    { label: "coinsure2", value: patientRecord.coinsure2 },
                    { label: "Medicaid", value: patientRecord.ss },
                    { label: "SS_note", value: patientRecord.billnote },
                    { label: "coins_no", value: patientRecord.coins_no },
                    { label: "is_primary", value: patientRecord.is_primary },
                    { label: "sub_lname", value: patientRecord.sub_lname },
                    { label: "sub_fname", value: patientRecord.sub_fname },
                    { label: "billto", value: patientRecord.billto },
                    { label: "billaddr1", value: patientRecord.billaddr1 },
                    { label: "billaddr2", value: patientRecord.billaddr2 },
                    { label: "billphone", value: patientRecord.billphone },
                  ]}
                />
              </Paper>

              <Paper sx={{ marginTop: 2, maxWidth: 700, p: 2 }}>
                <Typography variant="h6">Other</Typography>
                <PRTable
                  maxWidth={600}
                  rows={[
                    { label: "Level", value: patientRecord.level },
                    { label: "MNCODE", value: patientRecord.mncode },
                    { label: "ICD2", value: patientRecord.icd2 },
                    { label: "ICD3", value: patientRecord.icd3 },
                    { label: "IVDATE", value: patientRecord.ivdate },
                    { label: "status", value: patientRecord.status },
                    { label: "Note 1", value: patientRecord.note1 },
                    { label: "Note 2", value: patientRecord.note2 },
                  ]}
                />
              </Paper>
            </Stack>
          </Box>
          <Box sx={{ p: 1 }}>
            <Paper sx={{ marginTop: 2, maxWidth: 700, p: 2 }}>
              <Typography variant="h6">Options</Typography>

              <Box maxWidth={200} marginTop={1}>
                <Stack spacing={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      navigate(`/patients/${patientRecord.recordno}/files`)
                    }
                  >
                    View Files
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      navigate(`/patients/${patientRecord.recordno}/add-file`)
                    }
                  >
                    Add New File
                  </Button>
                </Stack>
              </Box>
            </Paper>
          </Box>
        </Container>
      </Container>
    );
  }
};
