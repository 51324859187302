import { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import * as gc from "../state/global_context";
import * as loginService from "./login_service";
import * as bcc from "./browser_credential_cache";

interface LoginFormData {
  username: string;
  password: string;
}

export const LoginComponent = () => {
  const [, globalStateDispatch] = gc.useGlobalContext();

  // Check the browser cache for credentials
  const cachedCredentials = bcc.getCredentials();
  if (cachedCredentials) {
    useEffect(() => {
      const checkCredentials = async () => {
        const remainingValidity = await loginService.getRemainingValidity(
          cachedCredentials
        );
        if (remainingValidity > 0) {
          //        console.log(`Cached credentials remaining validity: ${remainingValidity}`);
          globalStateDispatch({
            type: "SET_CREDENTIALS",
            auth: cachedCredentials,
          });
        } else {
          bcc.deleteCredentials();
        }
      };
      checkCredentials();
    });
  }

  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const { handleSubmit, control } = useForm<LoginFormData>();

  const onSubmit = handleSubmit(async (data: LoginFormData) => {
    try {
      const credentials = await loginService.login(
        data.username,
        data.password
      );

      globalStateDispatch({ type: "SET_CREDENTIALS", auth: credentials });

      navigate("/");
    } catch (error) {
      console.log(error);
      setErrorMessage("Login failed");
    }
  });

  return (
    <Container maxWidth="sm">
      <h2>MLTC login</h2>
      <form onSubmit={onSubmit}>
        <div>
          <Controller
            control={control}
            name="username"
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="username"
                value={value}
                onChange={onChange}
                error={!!error}
              />
            )}
            rules={{ required: "username required" }}
          />
        </div>
        <div>
          <Controller
            control={control}
            name="password"
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="password"
                value={value}
                onChange={onChange}
                error={!!error}
                type="password"
              />
            )}
            rules={{ required: "password required" }}
          />
        </div>
        <div>
          <Button variant="contained" color="primary" type="submit">
            login
          </Button>
        </div>
      </form>
      {errorMessage && (
        <Alert variant="outlined" severity="error">
          {errorMessage}
        </Alert>
      )}
    </Container>
  );
};
