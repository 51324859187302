import React from "react";
import { UserData } from "../users/api_types";

import * as gc from "../state/global_context";

export const GetUserDataComponent = () => {
  const [globalState, globalStateDispatch] = gc.useGlobalContext();

  const getUserData = async () => {
    if (globalState.auth.userid < 0) {
      return;
    }

    const getUserDataResponse = await globalState.mportalEndpoint.get<UserData>(
      `/users/${globalState.auth.userid}`
    );
    globalStateDispatch({
      type: "SET_USERDATA",
      userData: getUserDataResponse.data,
    });
  };

  React.useEffect(() => {
    getUserData();
  }, [globalState.auth.userid]);

  return <span />;
};
