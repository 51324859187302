import * as React from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import DownloadIcon from "@mui/icons-material/Download";
import { NavLink, useNavigate } from "react-router-dom";
import * as datefns from "date-fns";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import * as gc from "../state/global_context";
import { useCurrentPatientContext } from "./current_patient_context";
import { PatientFile } from "../patient_files/api_types";

const DownloadPatientFileButton = ({
  patientFile,
}: {
  patientFile: PatientFile;
}) => {
  const link = React.useRef<HTMLAnchorElement | null>(null);
  const [globalState] = gc.useGlobalContext();

  return (
    <Tooltip title="Download">
      <IconButton href={encodeURI(`${gc.MPORTAL_BACKEND_ENDPOINT}/api/v1/office/patient-files/download/${patientFile.id}?authorization=bearer ${globalState.auth.token}`)}>
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  );
};

interface PatientFilesDataGridProps {
  patientFiles: PatientFile[];
}

const PatientFilesDataGrid = ({ patientFiles }: PatientFilesDataGridProps) => {
  const patientRecord = useCurrentPatientContext();
  const navigate = useNavigate();

  if (!patientRecord) {
    return <div />;
  }

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },
    { field: "category", headerName: "Category", width: 150 },
    {
      field: "addDate",
      type: "date",
      headerName: "Date",
      width: 120,
      valueGetter: ({ value }) => datefns.format(new Date(value), "yyyy-MM-dd"),
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 500,
      valueGetter: ({ value }) =>
        value ? (value.length <= 80 ? value : value.slice(0, 80) + " ...") : "",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params: GridRenderCellParams<PatientFile>) => {
        return (
          <span>
            <Tooltip title="Open">
              <IconButton
                onClick={() =>
                  navigate(
                    `/patients/${patientRecord.recordno}/patient-file/${params.id}`
                  )
                }
              >
                <FileOpenIcon />
              </IconButton>
            </Tooltip>
            {params.row.userFilename ? (
              <DownloadPatientFileButton patientFile={params.row} />
            ) : (
              ""
            )}
          </span>
        );
      },
    },
  ];

  const rows = patientFiles;

  return (
    <div style={{ height: "60vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        sx={{ marginTop: 2 }}
      />
    </div>
  );
};

const AddPatientFileCard = () => {
  const patientRecord = useCurrentPatientContext();
  if (!patientRecord) {
    return <div />;
  }
  const navigate = useNavigate();

  return (
    <Card sx={{ marginTop: 2 }}>
      <CardContent>
        <Typography variant="h6">Actions</Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            navigate(`/patients/${patientRecord.recordno}/add-file`)
          }
        >
          New File
        </Button>
      </CardContent>
    </Card>
  );
};

export const PatientFiles = () => {
  const patientRecord = useCurrentPatientContext();

  const [patientFiles, setPatientFiles] = React.useState<PatientFile[] | null>(
    null
  );
  const [globalState] = gc.useGlobalContext();
  const endpoint = globalState.mportalEndpoint;

  const getPatientFiles = async () => {
    if (!patientRecord) {
      return;
    }
    const res = await endpoint.get<PatientFile[]>(`office/patient-files/`, {
      params: { patientId: patientRecord.recordno },
    });
    setPatientFiles(res.data);
  };

  React.useEffect(() => {
    getPatientFiles();
  }, [patientRecord]);

  if (!patientRecord || patientFiles === null) {
    return (
      <Container maxWidth="xl" sx={{ p: 2 }}>
        <p>Loading...</p>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ p: 1 }}>
      <Typography variant="h5" sx={{marginBottom: 2}}>
        <NavLink to={`/patients/${patientRecord.recordno}/overview`}>
          {patientRecord.lastname}, {patientRecord.firstname}{" "}
          {patientRecord.midname} (#{patientRecord.recordno})
        </NavLink>{" "}
        / Files
      </Typography>
      <PatientFilesDataGrid patientFiles={patientFiles} />
      <AddPatientFileCard />
    </Container>
  );
};
