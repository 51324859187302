import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import * as rhf from "react-hook-form";

import * as gc from "../state/global_context";
import { useCurrentPatientContext } from "./current_patient_context";
import {
  PatientFileTemplate,
} from "../patient_files/api_types";
import {
  usePatientFileTemplates,
  usePatientFileTemplateCategories,
} from "../patient_files/hooks";

interface AddPatientFileFormData {
  category: string;
  notes: string;
  attachment: FileList;
}

const ApplyTemplateErrorMessage = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  //  onConfirm: () => void;
}) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Delete notes contents first</DialogTitle>
      <DialogContent>
        To apply a template, please first delete the contents of the
        &quot;notes&quot; field.
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export const AddPatientFile = () => {
  const patientRecord = useCurrentPatientContext();

  const pfTemplates = usePatientFileTemplates();
  const pftCategories = usePatientFileTemplateCategories();
  const { register, handleSubmit, control, setValue, getValues } =
    rhf.useForm<AddPatientFileFormData>();
  const [openApplyTemplateErrorMessage, setOpenApplyTemplateErrorMessage] =
    React.useState<boolean>(false);
  const [selectedTemplateId, setSelectedTemplateId] =
    React.useState<string>("invalid");

  const navigate = useNavigate();
  const [globalState] = gc.useGlobalContext();

  if (pfTemplates === null || !pftCategories || !patientRecord) {
    return (
      <Container maxWidth="xl" sx={{ p: 2 }}>
        <p>Loading...</p>
      </Container>
    );
  }

  const getSelectedTemplate = () =>
    pfTemplates.find(
      (pft: PatientFileTemplate) => pft.id.toString() === selectedTemplateId
    );

  const onUploadProgress = (event: ProgressEvent) => {
    console.log("upload progress", event);
  };

  const onSubmit = handleSubmit(async (data: AddPatientFileFormData) => {
    const formData = new FormData();
    formData.append("attachment", data.attachment[0]);
    formData.append("category", data.category);
    formData.append("notes", data.notes);
    formData.append("patientId", patientRecord.recordno.toString());
    await globalState.mportalEndpoint.post(
      `office/patient-files/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      }
    );
    navigate(`/patients/${patientRecord.recordno}/files`);
  });

  const handleApplyTemplate = () => {
    const template = getSelectedTemplate();
    if (!template || !template.notes) {
      return;
    }

    const notes = getValues("notes");

    if (notes.trim().length > 0) {
      setOpenApplyTemplateErrorMessage(true);
    } else if (notes !== template.notes) {
      setValue("notes", template.notes);
    }
  };

  const attachmentRegister = register("attachment");

  return (
    <Container maxWidth="xl" sx={{ p: 1 }}>
      <Typography variant="h5" component="h2" sx={{ marginBottom: 2 }}>
        <Link to={`/patients/${patientRecord.recordno}/overview`}>
          {patientRecord.lastname}, {patientRecord.firstname}{" "}
          {patientRecord.midname} (#{patientRecord.recordno})
        </Link>{" "}
        / Add File
      </Typography>
      <form onSubmit={onSubmit}>
        <Container maxWidth="xl" sx={{ display: "flex" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Paper sx={{ p: 2 }}>
              <Grid
                container
                spacing={1}
                sx={{ marginTop: 2, marginBottom: 2 }}
              >
                <Grid item xs={12} md={2} lg={1}>
                  Category
                </Grid>
                <Grid item xs={12} md={10} lg={11}>
                  <FormControl sx={{ minWidth: 200 }}>
                    <rhf.Controller
                      control={control}
                      name="category"
                      defaultValue=""
                      shouldUnregister={true}
                      rules={{ required: true }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Select
                          value={value}
                          onChange={onChange}
                          error={!!error}
                        >
                          {pftCategories.categories.map((cat: string) => (
                            <MenuItem value={cat} key={cat}>
                              {cat}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2} lg={1}>
                  Template
                </Grid>
                <Grid item xs={12} md={10} lg={11}>
                  <Stack direction="row" spacing={2}>
                    <FormControl sx={{ minWidth: 300 }}>
                      <Select
                        value={selectedTemplateId}
                        onChange={
                          (event: SelectChangeEvent) =>
                            setSelectedTemplateId(event.target.value)
                          //                    const template = pfTemplates.find((pft: PatientFileTemplate) => pft.id.toString() === event.target.value);
                        }
                      >
                        <MenuItem value="invalid" key="invalid">
                          --- Optional: select a template ---
                        </MenuItem>
                        {pfTemplates.map((pft: PatientFileTemplate) => (
                          <MenuItem value={pft.id.toString()} key={pft.id}>
                            {pft.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleApplyTemplate}
                    >
                      Apply
                    </Button>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={2} lg={1}>
                  Attachment
                </Grid>
                <Grid item xs={12} md={10} lg={11}>
                  <Stack direction="row" spacing={2}>
                    <input
                      type="file"
                      {...attachmentRegister}
                      onChange={() => {
                        setSelectedTemplateId(selectedTemplateId);
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={2} lg={1}>
                  Notes
                </Grid>
                <Grid item xs={12} md={10} lg={11}>
                  <rhf.Controller
                    control={control}
                    name="notes"
                    defaultValue=""
                    shouldUnregister={true}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        multiline
                        maxRows={40}
                        minRows={15}
                        fullWidth
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Paper>
          </Box>
          <Box sx={{ marginLeft: 2 }}>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Box>
        </Container>
      </form>

      <ApplyTemplateErrorMessage
        open={openApplyTemplateErrorMessage}
        setOpen={setOpenApplyTemplateErrorMessage}
      />
    </Container>
  );
};
