import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
//import { useNavigate } from "react-router-dom";

import * as gc from "../state/global_context";
import { PageContainer } from "../common/page_container";
import { PageTitle } from "../common/page_title";
import { WaitingPatient, NewWaitingPatient } from "./api_types";
import { WaitingRoom } from "./waiting_room";
import { FindPatientModal } from "../patients/find_patient";
import { PatientRecord } from "../patients/api_types";
import { AddOrEditEncounter } from "./add_or_edit_encounter";

const getWaitingPatientDate = (
  waitingPatients: WaitingPatient[],
  patientId: number
): Date => {
  if (patientId <= 0) {
    return new Date();
  }
  const match = waitingPatients.find((wp) => wp.patientId === patientId);
  return match ? new Date(match.since) : new Date();
};

const ClearGoneListButton = ({
  waitingPatients,
  onGoneListCleared,
}: {
  waitingPatients: WaitingPatient[];
  onGoneListCleared: () => void;
}) => {
  const [globalState] = gc.useGlobalContext();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  /**
   * Called when the user clicks on the "Clear Gone List" button. Removes all
   * patients from the "Gone" room.
   */
  const handleClearGoneList = async () => {
    const toClear = waitingPatients.filter((wp) => wp.status === "GONE");
    for (const wp of toClear) {
      await globalState.mportalEndpoint.delete(
        `office/waiting-patients/${wp.patientId}`
      );
    }
    onGoneListCleared();
    handleClose();
  };

  return (
    <span>
      <Button
        variant="contained"
        color="secondary"
        sx={{ marginTop: 1 }}
        onClick={() => setOpen(true)}
      >
        Clear Gone list
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            Clear all patients in the Gone list?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ spacing: 1 }}
          >
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleClearGoneList} color="primary">
              OK
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </span>
  );
};

export const FrontDeskComponent = () => {
  //  const navigate = useNavigate();

  const [globalState] = gc.useGlobalContext();
  const [shouldShowFindPatientModal, setShouldShowFindPatientModal] =
    React.useState<boolean>(false);

  const [selectedPatientId, setSelectedPatientId] = React.useState(-1);
  const [waitingPatients, setWaitingPatients] = React.useState<
    Array<WaitingPatient>
  >([]);
  const [selectedPatientRecord, setSelectedPatientRecord] =
    React.useState<PatientRecord | null>(null);

  const getWaitingPatients = async () => {
    const res = await globalState.mportalEndpoint.get<Array<WaitingPatient>>(
      "office/waiting-patients"
    );
    if (res.data !== waitingPatients) {
      setWaitingPatients(res.data);
    }
  };

  React.useEffect(() => {
    getWaitingPatients();
  }, []);

  const updateWaitingPatient = async (
    patientId: number,
    update: WaitingPatient
  ) => {
    await globalState.mportalEndpoint.put<WaitingPatient>(
      `office/waiting-patients/${patientId}`,
      update
    );
    getWaitingPatients();
  };

  /**
   * Called after the user has clicked the "Admit existing patient" button and
   * selected a patient. Admits that patient to the "Waiting room" room.
   */
  const onAdmitPatientSelected = async (pt: PatientRecord) => {
    // Verify that the patient is not already admitted
    if (waitingPatients.find((wp) => wp.patientId === pt.recordno)) {
//      console.log(
//        `ERROR: ${pt.recordno} ${pt.lastname}, ${pt.firstname} - already admitted`
//      );
      return;
    }

    const newWp: NewWaitingPatient = {
      ptLastname: pt.lastname,
      ptFirstname: pt.firstname,
      patientId: pt.recordno,
      status: "WAITING_ROOM",
    };

//    console.log(`Admit - ${pt.recordno} ${pt.lastname}, ${pt.firstname}`);

    await globalState.mportalEndpoint.post<WaitingPatient>(
      `office/waiting-patients`,
      newWp
    );
    getWaitingPatients();
  };

  /**
   * Called by WaitingRoom components when the user clicks on and selects a patient.
   */
  const handleSetSelectedPatientId = async (patientId: number) => {
//    console.log("handleSetSelectedPatientId", patientId);
    setSelectedPatientId(patientId);
    if (patientId <= 0) {
      setSelectedPatientRecord(null);
    } else {
      const res = await globalState.mportalEndpoint.get<PatientRecord>(
        `office/patients/${patientId}`
      );
      setSelectedPatientRecord(res.data);
    }
  };

  const ExamRoomGridItem = ({
    roomId,
    roomName,
  }: {
    roomId: string;
    roomName: string;
  }) => (
    <Grid item xs={12}>
      <WaitingRoom
        waitingPatients={waitingPatients}
        roomId={roomId}
        roomName={roomName}
        minHeight={60}
        updateWaitingPatient={updateWaitingPatient}
        selectedPatientId={selectedPatientId}
        setSelectedPatientId={handleSetSelectedPatientId}
      />
    </Grid>
  );

  return (
    <PageContainer>
      <PageTitle>
        Front Desk
      </PageTitle>
      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid item md={4}>
          <WaitingRoom
            waitingPatients={waitingPatients}
            roomId="WAITING_ROOM"
            roomName="Waiting Room"
            minHeight={500}
            updateWaitingPatient={updateWaitingPatient}
            selectedPatientId={selectedPatientId}
            setSelectedPatientId={handleSetSelectedPatientId}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: 1 }}
            onClick={() => setShouldShowFindPatientModal(true)}
          >
            Admit Existing Patient
          </Button>
        </Grid>
        <Grid item md={4}>
          <Grid container item>
            <ExamRoomGridItem roomId="ROOM1" roomName="Room 1" />
            <ExamRoomGridItem roomId="ROOM2" roomName="Room 2" />
            <ExamRoomGridItem roomId="ROOM3" roomName="Room 3" />
            <ExamRoomGridItem roomId="ROOM4" roomName="Room 4" />
            <ExamRoomGridItem roomId="ROOM5" roomName="Room 5" />
          </Grid>
        </Grid>
        <Grid item md={4}>
          <WaitingRoom
            waitingPatients={waitingPatients}
            roomId="GONE"
            roomName="Gone"
            minHeight={500}
            updateWaitingPatient={updateWaitingPatient}
            selectedPatientId={selectedPatientId}
            setSelectedPatientId={handleSetSelectedPatientId}
          />
          <ClearGoneListButton
            waitingPatients={waitingPatients}
            onGoneListCleared={getWaitingPatients}
          />
        </Grid>
      </Grid>

      <AddOrEditEncounter
        selectedPatientRecord={selectedPatientRecord}
        date={getWaitingPatientDate(waitingPatients, selectedPatientId)}
      />

      <FindPatientModal
        showModal={shouldShowFindPatientModal}
        closeModal={() => setShouldShowFindPatientModal(false)}
        patientConfirmed={onAdmitPatientSelected}
      />
    </PageContainer>
  );
};
