import * as React from "react";
import Container from "@mui/material/Container";

import * as gc from "../state/global_context";

interface BackendVersionResponse {
  buildMode: string;
  buildId: string;
}

export const AboutMPortal = () => {
  const [globalState] = gc.useGlobalContext();
  const endpoint = globalState.mportalEndpoint;
  const [backendVersion, setBackendVersion] =
    React.useState<BackendVersionResponse | null>(null);

  const getBackendVersion = async () => {
    const res = await endpoint.get<BackendVersionResponse>("system/version");
    setBackendVersion(res.data);
  };

  React.useEffect(() => {
    getBackendVersion();
  }, []);

  const BackendVersion = () => {
    if (backendVersion === null) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        Backend build mode: {backendVersion.buildMode}
        <br />
        Backend build ID: {backendVersion.buildId}
      </div>
    );
  };

  return (
    <Container maxWidth="xl" sx={{ p: 2 }}>
      Developer info:
      <p>
        Backend: {process.env.REACT_APP_MPORTAL_BACKEND_ENDPOINT}
        <br />
        Frontend build mode: {process.env.REACT_APP_MPORTAL_BUILD_MODE}
        <br />
        Frontend build ID: {process.env.REACT_APP_MPORTAL_BUILD_ID ?? "undefined"}
      </p>
      <BackendVersion />
    </Container>
  );
};
