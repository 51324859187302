import React, { createContext, useContext, useReducer } from "react";
import axios, { AxiosInstance } from "axios";

import * as bcc from "../auth/browser_credential_cache";
import { ApiCredentials } from "../auth/api_credentials";
import { ProviderData } from "../providers/api_types";
import { UserData } from "../users/api_types";

export const MPORTAL_BACKEND_ENDPOINT =
  process.env.REACT_APP_MPORTAL_BACKEND_ENDPOINT;

export type GlobalAction =
  | {
      type: "SET_CREDENTIALS";
      auth: ApiCredentials;
    }
  | {
      type: "LOGOUT";
    }
  | {
      type: "SET_PROVIDERS";
      providers: ProviderData[];
    }
  | {
      type: "SET_USERDATA";
      userData: UserData;
    };

export type GlobalState = {
  /**
   * User credentials needed to authenticate to the backend.
   */
  auth: ApiCredentials;

  /** Axios instance configured with the MPORTAL endpoint baseURL and authorization
   * header
   */
  mportalEndpoint: AxiosInstance;

  /**
   * List of providers. Stored in global state because it's used pretty much everywhere.
   */
  providers: ProviderData[];

  userData: UserData;
};

const initialState: GlobalState = {
  auth: {
    username: "",
    token: "",
    userid: -1,
  },
  mportalEndpoint: axios.create({
    baseURL: `${MPORTAL_BACKEND_ENDPOINT}/api/v1/`,
  }),
  providers: [],
  userData: {
    id: -1,
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    isStaff: false,
    isActive: false,
    isSuperuser: false,
    lastLogin: "",
    dateJoined: "",
  },
};

export const GlobalContext = createContext<
  [GlobalState, React.Dispatch<GlobalAction>]
>([initialState, () => initialState]);

type GlobalStateProviderProps = {
  reducer: React.Reducer<GlobalState, GlobalAction>;
  children: React.ReactElement;
};

export const reducer = (
  state: GlobalState,
  action: GlobalAction
): GlobalState => {
  switch (action.type) {
    case "SET_CREDENTIALS": {
      bcc.storeCredentials(action.auth);
      return {
        ...state,
        auth: action.auth,
        mportalEndpoint: axios.create({
          baseURL: `${MPORTAL_BACKEND_ENDPOINT}/api/v1/`,
          headers: { Authorization: `bearer ${action.auth.token}` },
        }),
      };
    }
    case "LOGOUT": {
      bcc.deleteCredentials();
      return {
        ...initialState,
      };
    }
    case "SET_PROVIDERS": {
      return {
        ...state,
        providers: action.providers,
      };
    }
    case "SET_USERDATA": {
      return {
        ...state,
        userData: action.userData,
      };
    }
    default:
      return state;
  }
};

export const GlobalStateProvider = ({
  reducer,
  children,
}: GlobalStateProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <GlobalContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
