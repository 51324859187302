import * as React from "react";
import { AxiosError } from "axios";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { Link, useParams } from "react-router-dom";

import * as rhf from "react-hook-form";
import * as gc from "../state/global_context";
import { UserData, UpdateUserRequest } from "../users/api_types";

interface EditUserFormData {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  isStaff: boolean;
  isActive: boolean;
  isSuperuser: boolean;
}

const EditUserForm = ({
  userData,
  setErrorMessage,
}: {
  userData: UserData;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [globalState] = gc.useGlobalContext();
  const form = rhf.useForm<EditUserFormData>();

  const onSubmit = form.handleSubmit(async (data: EditUserFormData) => {
    const update: UpdateUserRequest = {
      id: userData.id,
      ...data,
      dateJoined: userData.dateJoined,
    };

    try {
      const res = await globalState.mportalEndpoint.put<UserData>(
        `users/${userData.id}`,
        update
      );
      form.reset(res.data);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(
        error instanceof AxiosError
          ? "Error: " + error.response?.data.toString()
          : "Error"
      );
    }
  });
  return (
    <div>
      <Typography variant="h6" sx={{ marginBottom: 1 }}>
        Profile and Permissions
      </Typography>
      <form onSubmit={onSubmit}>
        <Box sx={{ maxWidth: 200 }}>
          <Stack spacing={1}>
            <rhf.Controller
              control={form.control}
              name="username"
              defaultValue={userData.username}
              shouldUnregister={true}
              render={({ field, fieldState }) => (
                <TextField
                  label="Username"
                  {...field}
                  error={!!fieldState.error}
                  fullWidth
                  inputProps={{ maxLength: 30, minLength: 2 }}
                />
              )}
            />
            <rhf.Controller
              control={form.control}
              name="firstName"
              defaultValue={userData.firstName}
              shouldUnregister={true}
              render={({ field, fieldState }) => (
                <TextField
                  label="Firstname"
                  {...field}
                  error={!!fieldState.error}
                  fullWidth
                  inputProps={{ maxLength: 30 }}
                />
              )}
            />
            <rhf.Controller
              control={form.control}
              name="lastName"
              defaultValue={userData.lastName}
              shouldUnregister={true}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Lastname"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  fullWidth
                  inputProps={{ maxLength: 30 }}
                />
              )}
            />
            <rhf.Controller
              control={form.control}
              name="email"
              defaultValue={userData.email}
              shouldUnregister={true}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="email"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  fullWidth
                  inputProps={{ maxLength: 30 }}
                />
              )}
            />
            <rhf.Controller
              control={form.control}
              name="isStaff"
              defaultValue={userData.isStaff}
              shouldUnregister={true}
              render={({ field }) => (
                <FormControlLabel
                  label="Staff?"
                  labelPlacement="start"
                  control={<Checkbox defaultChecked={userData.isStaff} />}
                  {...field}
                />
              )}
            />
            <rhf.Controller
              control={form.control}
              name="isActive"
              defaultValue={userData.isActive}
              shouldUnregister={true}
              render={({ field }) => (
                <FormControlLabel
                  label="Active?"
                  labelPlacement="start"
                  control={<Checkbox defaultChecked={userData.isActive} />}
                  {...field}
                />
              )}
            />
            <rhf.Controller
              control={form.control}
              name="isSuperuser"
              defaultValue={userData.isSuperuser}
              shouldUnregister={true}
              render={({ field }) => (
                <FormControlLabel
                  label="Superuser?"
                  labelPlacement="start"
                  control={<Checkbox defaultChecked={userData.isSuperuser} />}
                  {...field}
                />
              )}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!form.formState.isDirty}
            >
              Save
            </Button>
          </Stack>
        </Box>
      </form>
    </div>
  );
};

interface ChangePasswordFormData {
  newPassword: string;
  newPasswordVerify: string;
}

const ChangePasswordForm = ({
  userData,
  setErrorMessage,
}: {
  userData: UserData;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [globalState] = gc.useGlobalContext();
  const form = rhf.useForm<ChangePasswordFormData>();
  const [matching, setMatching] = React.useState(false);

  const onSubmit = form.handleSubmit(async (data: ChangePasswordFormData) => {
    if (data.newPassword !== data.newPasswordVerify) {
      setErrorMessage("Passwords don't match");
      return;
    }

    try {
      await globalState.mportalEndpoint.put<UserData>(
        `users/${userData.id}/password`,
        { password: data.newPassword }
      );
      form.reset({ newPassword: "", newPasswordVerify: "" });
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(
        error instanceof AxiosError
          ? "Error: " + error.response?.data.toString()
          : "Error"
      );
    }
  });

  return (
    <div>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Password
      </Typography>
      <form onSubmit={onSubmit}>
        <Box sx={{ maxWidth: 200 }}>
          <Stack spacing={1}>
            <rhf.Controller
              control={form.control}
              name="newPassword"
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="New password"
                  value={field.value}
                  onChange={(event) => {
                    setMatching(
                      event.target.value === form.getValues("newPasswordVerify")
                    );
                    field.onChange(event);
                  }}
                  error={!!error}
                  type="password"
                  inputProps={{ minLength: 8 }}
                />
              )}
              rules={{ required: "password required" }}
            />
            <rhf.Controller
              control={form.control}
              name="newPasswordVerify"
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="New password (confirm)"
                  value={field.value}
                  onChange={(event) => {
                    setMatching(
                      event.target.value === form.getValues("newPassword")
                    );
                    field.onChange(event);
                  }}
                  error={!!error}
                  type="password"
                  inputProps={{ minLength: 8 }}
                />
              )}
              rules={{ required: "New password (confirm) required" }}
            />
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={!form.formState.isDirty || !matching}
            >
              Save
            </Button>
          </Stack>
        </Box>
      </form>
    </div>
  );
};

type EditUserParams = {
  userId: string;
};

export const EditUser = () => {
  const params = useParams<EditUserParams>();

  const userId = params.userId ? parseInt(params.userId) : -1;
  const [userData, setUserData] = React.useState<UserData | null>(null);
  const [globalState] = gc.useGlobalContext();
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [pwErrMessage, setPwErrMessage] = React.useState<string>("");

  const getUserData = async () => {
    const res = await globalState.mportalEndpoint.get<UserData>(
      `users/${userId}`
    );
    setUserData(res.data);
  };

  React.useEffect(() => {
    getUserData();
  }, [params.userId]);

  if (!userData) {
    return (
      <Container maxWidth="xl" sx={{ p: 2 }}>
        <p>Loading...</p>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ p: 1 }}>
      <Typography variant="h5">
        <Link to="/users">Users</Link> / {userData.username}
      </Typography>
      <Paper sx={{ p: 2 }}>
        {errorMessage.length === 0 ? (
          ""
        ) : (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        <EditUserForm
          userData={userData}
          setErrorMessage={setErrorMessage}
        />
      </Paper>
      <Paper sx={{ marginTop: 2, p: 2 }}>
        {pwErrMessage.length === 0 ? (
          ""
        ) : (
          <Alert severity="error">{pwErrMessage}</Alert>
        )}
        <ChangePasswordForm
          userData={userData}
          setErrorMessage={setPwErrMessage}
        />
      </Paper>
    </Container>
  );
};
