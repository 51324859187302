import React from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";

import { UserData } from "./users/api_types";
import { UpdateChecker } from "./about/update_checker";
import * as gc from "./state/global_context";
import * as loginService from "./auth/login_service";

type TimerId = ReturnType<typeof setTimeout>;

type RefreshCredentialContext = {
  globalState: gc.GlobalState;
  globalStateDispatch: React.Dispatch<gc.GlobalAction>;
  timeoutId: TimerId | null;
  navigate: NavigateFunction;
  setTimeoutId: React.Dispatch<React.SetStateAction<TimerId | null>>;
};

export const checkCredentialValidity = async (
  rcc: RefreshCredentialContext
) => {
  const remainingValidity = await loginService.getRemainingValidity(
    rcc.globalState.auth
  );

  if (remainingValidity <= 0) {
    // If the token has expired, then we can't refresh it anymore. Don't bother and
    // instead just initiate the logout procedure.
    console.log(`${Date.now() / 1000} - Auth token expired. Logging out.`);
    rcc.setTimeoutId(null);
    rcc.globalStateDispatch({ type: "LOGOUT" });
    rcc.navigate("/");
    return;
  }

  if (rcc.timeoutId !== null) {
    // If for some reason a refresh has already been scheduled, then don't double schedule it here.
    return;
  }

  // Callback function invoked on a timer to refresh the auth token.
  const refreshApiToken = async () => {
    const nowSec = Date.now() / 1000;
    rcc.setTimeoutId(null);
    try {
      const response = await rcc.globalState.mportalEndpoint.post(
        "login/refresh"
      );
      rcc.globalStateDispatch({
        type: "SET_CREDENTIALS",
        auth: response.data,
      });
      console.log(`${nowSec} - Refreshed auth token`);
    } catch (error) {
      // Failed to refresh token. Initiate the logout procedure.
      console.log(`${nowSec} - Failed to refresh auth token. Logging out.`);
      rcc.globalStateDispatch({ type: "LOGOUT" });
      rcc.navigate("/");
    }
  };

  // Refresh token one minute before it's scheduled to expire, but no less than
  // 10 seconds from now.
  const secondsUntilRefresh = Math.max(10, remainingValidity - 60);

  rcc.setTimeoutId(setTimeout(refreshApiToken, secondsUntilRefresh * 1000));
};

export const AdminMenu = () => {
  const [globalState] = gc.useGlobalContext();

  const userData: UserData = globalState.userData;
  const [adminAnchorEl, setAdminAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const navigate = useNavigate();

  if (userData.username.length === 0 || !userData.isSuperuser) {
    return <span />;
  }

  return (
    <div>
      <Button
        color="inherit"
        onClick={(event) => setAdminAnchorEl(event.currentTarget)}
      >
        Admin
      </Button>
      <Menu
        id="menu-admin"
        anchorEl={adminAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(adminAnchorEl)}
        onClose={() => setAdminAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setAdminAnchorEl(null);
            navigate("/users");
          }}
        >
          Users
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAdminAnchorEl(null);
            navigate("/admin/patient-file-categories");
          }}
        >
          Patient Files
        </MenuItem>
      </Menu>
    </div>
  );
};

export const OfficeMenu = () => {
  const [officeAnchorEl, setOfficeAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  return (
    <div>
      <Button
        color="inherit"
        onClick={(event) => setOfficeAnchorEl(event.currentTarget)}
      >
        Office
      </Button>
      <Menu
        id="menu-office"
        anchorEl={officeAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(officeAnchorEl)}
        onClose={() => setOfficeAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setOfficeAnchorEl(null);
            navigate("/encounters/browse/today");
          }}
        >
          Encounters
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOfficeAnchorEl(null);
            navigate("/front-desk");
          }}
        >
          Front Desk
        </MenuItem>
      </Menu>
    </div>
  );
};

const UserMenu = ({ handleLogout }: {handleLogout: () => void}) => {
  const [globalState] = gc.useGlobalContext();
  const navigate = useNavigate();

  const [userAnchorEl, setUserAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  return (
    <div>
      <IconButton
        size="large"
        onClick={(event) => setUserAnchorEl(event.currentTarget)}
        color="inherit"
      >
        <AccountCircle />
        <Typography variant="button" component="div">
          {globalState.auth.username}
        </Typography>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={userAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(userAnchorEl)}
        onClose={() => setUserAnchorEl(null)}
      >
        <MenuItem onClick={() => {
          setUserAnchorEl(null);
          handleLogout(); }
        }>Logout</MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/about");
            setUserAnchorEl(null);
          }}
        >
          About
        </MenuItem>
      </Menu>
    </div>
  );
};

export const NavigationBar = () => {
  const [globalState, globalStateDispatch] = gc.useGlobalContext();
  const [credentialRefreshTimeoutId, setCredentialRefreshTimeoutId] =
    React.useState<TimerId | null>(null);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (credentialRefreshTimeoutId === null) {
      checkCredentialValidity({
        globalState,
        globalStateDispatch,
        timeoutId: credentialRefreshTimeoutId,
        setTimeoutId: setCredentialRefreshTimeoutId,
        navigate,
      });
    }
  });

  const handleLogout = () => {
    globalStateDispatch({ type: "LOGOUT" });
    if (credentialRefreshTimeoutId !== null) {
      clearTimeout(credentialRefreshTimeoutId);
      setCredentialRefreshTimeoutId(null);
    }
    navigate("/");
  };

  return (
    <div>
      <UpdateChecker />
      <AppBar position="static">
        <Toolbar>
          <Button color="inherit" component={Link} to="/patients">
            Patients
          </Button>
          <OfficeMenu />
          <AdminMenu />
          <Box sx={{ flexGrow: 1 }} />
          <UserMenu handleLogout={handleLogout}/>
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};
