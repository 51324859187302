import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import * as gc from "./state/global_context";
import { GetUserDataComponent } from "./users/get_userdata";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LoginComponent } from "./auth/login_component";
import { NavigationBar } from "./navigation_bar";
import { BrowseEncounters } from "./encounters/browse_encounters";
import { EditEncounter } from "./encounters/edit_encounter";
import { FrontDeskComponent } from "./front_desk/front_desk_component";
import { HomeComponent } from "./home/home_component";
import { AboutMPortal } from "./about/about_mportal";
import { ProvidersLoader } from "./providers/providers_loader";
import { PatientsSearch } from "./patients/patients_search";
import { PatientHome } from "./patients/patient_home";
import { PatientOverview } from "./patients/patient_overview";
import { PatientFiles } from "./patients/patient_files";
import { AddPatientFile } from "./patients/add_patient_file";
import { ManagePatientFile } from "./patients/manage_patient_file";
import { ManageUsers } from "./users/manage_users";
import { EditUser } from "./users/edit_user";
import { NewUser } from "./users/new_user";
import { ManagePatientFileCategoriesAndTemplates } from "./patient_files/edit_file_categories";
import { ManagePatientFileTemplate } from "./patient_files/manage_template";
import { NewPatientFileTemplateComponent } from "./patient_files/new_template";

const MainRouter = () => {
  // Routes to define the React Router component routes
  return (
    <div>
      <GetUserDataComponent />
      <ProvidersLoader />
      <NavigationBar />
      <Routes>
        <Route path="/" element={<HomeComponent />} />
        <Route path="/patients" element={<PatientsSearch />} />
        <Route path="/patients/:patientId" element={<PatientHome />}>
          <Route path="" element={<PatientOverview />} />
          <Route path="overview" element={<PatientOverview />} />
          <Route path="files" element={<PatientFiles />} />
          <Route path="add-file" element={<AddPatientFile />} />
          <Route path="patient-file/:fileId" element={<ManagePatientFile />} />
        </Route>
        <Route path="/encounters/browse/:date" element={<BrowseEncounters />} />
        <Route
          path="/encounters/edit/:encounterId"
          element={<EditEncounter />}
        />
        <Route path="/front-desk" element={<FrontDeskComponent />} />
        <Route path="/about" element={<AboutMPortal />} />
        <Route path="/users" element={<ManageUsers />} />
        <Route path="/users/:userId/edit" element={<EditUser/>}/>
        <Route path="/users/new-user" element={<NewUser />} />
        <Route path="/admin/patient-file-categories" element={<ManagePatientFileCategoriesAndTemplates />} />
        <Route path="/admin/manage-patient-file-template/:templateId" element={<ManagePatientFileTemplate />} />
        <Route path="/admin/new-patient-file-template" element={<NewPatientFileTemplateComponent />} />
      </Routes>
    </div>
  );
};

const LoginPageOrMainRouter = () => {
  const [globalState] = gc.useGlobalContext();
  return globalState.auth.username ? <MainRouter /> : <LoginComponent />;
};

const App = () => {
  // - LocalizationProvider sets the adpater used by @mui/x-date-picker
  // - gc.GlobalStateProvider provides all components with access to global
  //   state without needing to prop-drill
  // - BrowserRouter to enable React Router component routing
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <gc.GlobalStateProvider reducer={gc.reducer}>
        <BrowserRouter>
          <LoginPageOrMainRouter />
        </BrowserRouter>
      </gc.GlobalStateProvider>
    </LocalizationProvider>
  );
};

export default App;
