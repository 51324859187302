import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  DataGrid,
  GridColumns,
  GridRowsProp,
  GridSelectionModel,
} from "@mui/x-data-grid";

import * as gc from "../state/global_context";
import { PatientRecord } from "./api_types";

type FindPatientComponentProps = {
  selectedPatientChanged: (patientRecord: PatientRecord | null) => void;
};

export const FindPatientComponent = (props: FindPatientComponentProps) => {
  const [lastname, setLastname] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [patientId, setPatientId] = React.useState("");
  const [globalState] = gc.useGlobalContext();
  const [searchResults, setSearchResults] = React.useState<PatientRecord[]>([]);

  const endpoint = globalState.mportalEndpoint;

  const searchResultColumns: GridColumns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "lastname", headerName: "Lastname", width: 130 },
    { field: "mid_initial", headerName: "Mid", width: 20 },
    { field: "firstname", headerName: "Firstname", width: 130 },
    { field: "dob", headerName: "DOB" },
    { field: "sex", headerName: "Sex", width: 40 },
    { field: "md", headerName: "MD", width: 40 },
    { field: "status", headerName: "Status", width: 60 },
    { field: "facility", headerName: "Facility", width: 150 },
    { field: "mccode", headerName: "INS", width: 70 },
  ];
  const searchResultRows: GridRowsProp = searchResults.map((record) => ({
    ...record,
    id: record.recordno,
    mid_initial: record.midname.slice(0, 1)
  }));
  //    { id: 1, lastname: "Lastname1", firstname: "Firstname1" },
  //    { id: 2, lastname: "Lastname2", firstname: "Firstname2" },
  //  ];

  const searchForPatients = async (
    searchLastname: string,
    searchFirstname: string,
    searchPatientId: string
  ) => {
    if (!searchLastname && !searchFirstname && !patientId) {
      return;
    }

    const params = {
      limit: 20,
      offset: 0,
      lastnameStartsWith: searchLastname ? searchLastname : undefined,
      firstnameStartsWith: searchFirstname ? searchFirstname : undefined,
      patientIdStartsWith: searchPatientId ? searchPatientId : undefined,
    };

    const res = await endpoint.get<PatientRecord[]>("office/patients", {
      params,
    });
    setSearchResults(res.data);
  };

  const onLastnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastname(event.target.value);
    searchForPatients(event.target.value, firstname, patientId);
  };

  const onFirstnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstname(event.target.value);
    searchForPatients(lastname, event.target.value, patientId);
  };

  const onPatientIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPatientId(event.target.value);
    searchForPatients(lastname, firstname, event.target.value);
  };

  const handleSelectionModelChange = (selectionModel: GridSelectionModel) => {
    if (selectionModel.length <= 0) {
      props.selectedPatientChanged(null);
    } else {
      const patientId = selectionModel[0] as number;

      const patientRecord = searchResults.find(
        (elem) => elem.recordno === patientId
      );

      props.selectedPatientChanged(patientRecord ? patientRecord : null);
    }
  };

  return (
    <div>
      <Stack direction="row" spacing={10}>
        <Stack spacing={1} maxWidth="sm">
          <TextField
            variant="standard"
            label="Lastname"
            onChange={onLastnameChange}
            value={lastname}
          />
          <TextField
            variant="standard"
            label="Firstname"
            onChange={onFirstnameChange}
            value={firstname}
          />
          <TextField
            variant="standard"
            label="Patient ID"
            onChange={onPatientIdChange}
            value={patientId}
          />
        </Stack>
        <div style={{ height: "50vh", width: "100%" }}>
          <DataGrid
            rows={searchResultRows}
            columns={searchResultColumns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            sx={{ mt: 2 }}
            onSelectionModelChange={handleSelectionModelChange}
          />
        </div>
      </Stack>
    </div>
  );
};

interface FindPatientModalProps {
  showModal: boolean;
  closeModal: () => void;
  patientConfirmed: (patientRecord: PatientRecord) => void;
}

export const FindPatientModal = (props: FindPatientModalProps) => {
  const [modalPatientRecord, setModalPatientRecord] =
    React.useState<PatientRecord | null>(null);

  return (
    <Modal open={props.showModal} onClose={props.closeModal}>
      <Card
        sx={{
          bgcolor: "background.paper",
          position: "absolute",
          top: "20%",
          left: "20%",
          width: "80%",
          transform: "translate(-15%, -20%)",
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Find Patient
        </Typography>
        <CardContent>
          <FindPatientComponent
            selectedPatientChanged={(patientRecord) => {
              setModalPatientRecord(patientRecord);
            }}
          />
        </CardContent>
        <CardActions>
          <Box
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!modalPatientRecord}
              onClick={() => {
                if (modalPatientRecord) {
                  props.patientConfirmed(modalPatientRecord);
                  props.closeModal();
                }
              }}
            >
              OK
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ m: 2 }}
              onClick={props.closeModal}
            >
              Cancel
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Modal>
  );
};
