import * as React from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import FormGroup from "@mui/material/FormGroup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { useParams, useNavigate } from "react-router-dom";
import * as datefns from "date-fns";

import * as gc from "../state/global_context";
import { useCurrentPatientContext } from "./current_patient_context";
import { PatientFile } from "../patient_files/api_types";

const DeletePatientFileButton = ({
  patientFile,
  onDelete,
}: {
  patientFile: PatientFile;
  onDelete?: (patientFileId: number) => void;
}) => {
  const [open, setOpen] = React.useState(false);
  const [confirmText, setConfirmText] = React.useState("");
  const [globalState] = gc.useGlobalContext();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (confirmText !== "delete") {
      return;
    }

    await globalState.mportalEndpoint.delete(
      `office/patient-files/${patientFile.id}`
    );

    if (onDelete) {
      onDelete(patientFile.id);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setConfirmText("");
  };

  return (
    <span>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => setOpen(true)}
        size="small"
      >
        Delete
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <DialogContent>
              <DialogContentText>
                Delete this file? This action cannot be undone. To delete, type
                &quot;delete&quot; and click delete
              </DialogContentText>

              <TextField
                value={confirmText}
                label='type "delete" here'
                onChange={(event) => setConfirmText(event.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{ spacing: 1 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button variant="outlined" color="secondary" type="submit">
                  Delete
                </Button>
              </Box>
            </DialogActions>
          </FormGroup>
        </form>
      </Dialog>
    </span>
  );
};

const DownloadPatientFileAnchor = ({
  patientFile,
}: {
  patientFile: PatientFile;
}) => {
  const link = React.useRef<HTMLAnchorElement | null>(null);
  const [globalState] = gc.useGlobalContext();

  return (
    <span>
      <a ref={link} download={patientFile.userFilename} className="hidden" />
      <Button
        size="small"
        variant="outlined"
        color="secondary"
        href={encodeURI(
          `${gc.MPORTAL_BACKEND_ENDPOINT}/api/v1/office/patient-files/download/${patientFile.id}?authorization=bearer ${globalState.auth.token}`
        )}
      >
        Download
      </Button>
    </span>
  );
};

interface PRFieldProps {
  label: string;
  value: string;
}

const PRTable = (props: { rows: PRFieldProps[]; maxWidth: number }) => (
  <Table size="small" sx={{ maxWidth: props.maxWidth }}>
    <TableBody>
      {props.rows.map((row) => (
        <TableRow key={row.label}>
          <TableCell>
            <Typography variant="body1">{row.label}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">{row.value}</Typography>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

type ManagePatientFileParams = {
  fileId: string;
};

export const ManagePatientFile = () => {
  const params = useParams<ManagePatientFileParams>();
  const patientRecord = useCurrentPatientContext();

  const [pfile, setPfile] = React.useState<PatientFile | null>(null);
  const [globalState] = gc.useGlobalContext();
  const navigate = useNavigate();

  const getPatientFile = async () => {
    if (!patientRecord) {
      return;
    }
    const res = await globalState.mportalEndpoint.get<PatientFile>(
      `office/patient-files/metadata/${params.fileId}`
    );
    setPfile(res.data);
  };

  React.useEffect(() => {
    getPatientFile();
  }, [patientRecord, params.fileId]);

  if (!patientRecord || pfile === null) {
    return (
      <Container maxWidth="xl" sx={{ p: 2 }}>
        <p>Loading...</p>
      </Container>
    );
  }

  const onDelete = () => {
    navigate(`/patients/${patientRecord.recordno}/files`);
  };

  return (
    <Container maxWidth="xl" sx={{ p: 2 }}>
      <Typography variant="h5">
        {patientRecord.lastname}, {patientRecord.firstname}{" "}
        {patientRecord.midname} (#{patientRecord.recordno}) - File {pfile.id}
      </Typography>

      <Paper sx={{ p: 1, marginTop: 1 }}>
        <Typography variant="h6" sx={{ marginTop: 3 }}>
          Metadata
        </Typography>
        <PRTable
          maxWidth={400}
          rows={[
            { label: "Added by", value: pfile.user.username },
            {
              label: "Date",
              value: datefns.format(new Date(pfile.addDate), "yyyy-MM-dd"),
            },
            { label: "Category", value: pfile.category },
            { label: "Attachment", value: pfile.userFilename },
          ]}
        />
      </Paper>

      <Paper sx={{ marginTop: 2, p: 1 }}>
        <Typography variant="h6">Actions</Typography>
        <Stack spacing={1}>
          {pfile.requesterCanDelete ? (
            <DeletePatientFileButton patientFile={pfile} onDelete={onDelete} />
          ) : (
            ""
          )}

          {pfile.userFilename && (
            <DownloadPatientFileAnchor patientFile={pfile} />
          )}
        </Stack>
      </Paper>

      <Paper
        sx={{ marginTop: 1, minHeight: 100, p: 1, fontFamily: "Monospace" }}
      >
        <Typography variant="h6">Notes</Typography>
        <pre>
          {pfile.notes && pfile.notes.trim().length > 0
            ? `${pfile.notes}`
            : "None"}
        </pre>
      </Paper>
    </Container>
  );
};
