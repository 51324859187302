import * as React from "react";

import * as datefns from "date-fns";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import * as gc from "../state/global_context";
import { EncounterData, ExportOffslipResponse } from "./api_types";

interface ExportOffslipModalProps {
  encounters: EncounterData[];
  date: Date;
  showModal: boolean;
  closeModal: () => void;
}

enum ExportStatus {
  NotStarted,
  Initiated,
  Success,
  Failure,
}

export const ExportOffslipModal = ({
  encounters,
  date,
  showModal,
  closeModal,
}: ExportOffslipModalProps): JSX.Element => {
  const [globalState] = gc.useGlobalContext();
  const endpoint = globalState.mportalEndpoint;

  const [exportStatus, setExportStatus] = React.useState(
    ExportStatus.NotStarted
  );
  const [exportResponse, setExportResponse] =
    React.useState<ExportOffslipResponse | null>(null);
  const dateStr = datefns.format(date, "yyyy-MM-dd");

  const handleExportClicked = async () => {
    setExportStatus(ExportStatus.Initiated);

    try {
      const response = await endpoint.post<ExportOffslipResponse>(
        "office/encounters/export-offslip",
        {
          dateMinimum: dateStr,
          dateMaximum: dateStr,
        }
      );
      setExportResponse(response.data);
      setExportStatus(ExportStatus.Success);
    } catch (error) {
      console.log("ERROR", error);
      setExportResponse(null);
      setExportStatus(ExportStatus.Failure);
    }
  };

  const handleClose = async () => {
    setExportResponse(null);
    setExportStatus(ExportStatus.NotStarted);
    closeModal();
  };

  const numServices = encounters.reduce(
    (sum, encounter) => sum + encounter.services.length,
    0
  );

  const StatusMessage = () => {
    switch (exportStatus) {
      case ExportStatus.Initiated:
        return (
          <div>
            <p>Exporting...</p>
            <p># Services to export: {numServices}</p>
          </div>
        );
      case ExportStatus.Success:
        return (
          <div>
            <p>Success!</p>
            <p>
              Services newly exported:{" "}
              {exportResponse ? exportResponse.numServicesExported : NaN}
            </p>
            <p>
              Services skipped because already detected in OFFSLIP:{" "}
              {exportResponse
                ? exportResponse.numServicesAlreadyInOffslip
                : NaN}
            </p>
            <p>You can close this dialog now.</p>
          </div>
        );
      case ExportStatus.Failure:
        return <div>Error encountered during export!!</div>;
      case ExportStatus.NotStarted:
        return (
          <div>
            <p># Services to export: {numServices}</p>
          </div>
        );
      default:
        return <div />;
    }
  };

  return (
    <Modal open={showModal}>
      <Card
        sx={{
          bgcolor: "background.paper",
          position: "absolute",
          top: "20%",
          left: "20%",
          width: "80%",
          transform: "translate(-15%, -20%)",
          p: 4,
        }}
      >
        <CardContent>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ marginBottom: 2 }}
          >
            Export {dateStr} encounters to Z:\DBASE\OFFSLIP.DBF ?
          </Typography>
          <StatusMessage />
        </CardContent>
        <CardActions>
          <Box
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={exportStatus !== ExportStatus.NotStarted}
              onClick={handleExportClicked}
            >
              Export
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ m: 2 }}
              onClick={handleClose}
            >
              Close
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Modal>
  );
};
