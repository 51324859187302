import * as React from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import * as datefns from "date-fns";

import * as gc from "../state/global_context";
import { PatientRecord } from "../patients/api_types";

import { EncounterData } from "../encounters/api_types";
import { EncounterDetail } from "../encounters/encounter_detail";

type AddOrEditEncounterProps = {
  date: Date;
  selectedPatientRecord: PatientRecord | null;
  onEncounterUpdated?: () => void;
};

const AddEncounter = (props: AddOrEditEncounterProps) => {
  const pt = props.selectedPatientRecord;

  const [globalState] = gc.useGlobalContext();
  const endpoint = globalState.mportalEndpoint;
  const navigate = useNavigate();

  const [providerShortid, setProviderShortId] = React.useState(pt ? pt.md : "");

  React.useEffect(() => {
    setProviderShortId(pt ? pt.md : "");
  }, [props.selectedPatientRecord]);

  if (!pt) {
    return <div />;
  }

  const handleAddNewEncounter = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const data = {
      ptLastname: pt.lastname,
      ptFirstname: pt.firstname,
      patientId: pt.recordno,
      providerShortid: providerShortid,
      date: datefns.format(props.date, "yyyy-MM-dd"),
      time: datefns.format(props.date, "HH:mm:ss"),
    };
    const res = await endpoint.post("office/encounters", data);
    if (props.onEncounterUpdated) {
      props.onEncounterUpdated();
    }

    navigate("/encounters/edit/" + res.data.encounterId);
  };

  return (
    <Card sx={{ width: "50%", minHeight: "15vh", p: 1, marginTop: 2 }}>
      <form onSubmit={handleAddNewEncounter}>
        <Typography variant="h6">
          {pt.lastname}, {pt.firstname} ({pt.recordno})
        </Typography>
        <CardContent>
          <FormGroup>
            <Typography variant="body1">
              No services recorded on {datefns.format(props.date, "yyyy-MM-dd")}
              . To record services, choose a provider and click &quot;Add new
              encounter&quot;.
            </Typography>
          </FormGroup>
          <FormGroup sx={{ marginTop: 2 }}>
            <FormLabel>Provider</FormLabel>
            <Select
              variant="outlined"
              label="Provider ID"
              size="small"
              value={providerShortid}
              onChange={(event) =>
                setProviderShortId(event.target.value as string)
              }
            >
              {globalState.providers.map((provider) => (
                <MenuItem key={provider.shortid} value={provider.shortid}>
                  {provider.shortid}
                </MenuItem>
              ))}
              {globalState.providers.find(
                (provider) => provider.shortid === "FD"
              ) ? (
                ""
              ) : (
                <MenuItem value="FD">FD</MenuItem>
              )}
            </Select>
          </FormGroup>
        </CardContent>
        <CardActions>
          <Button
            variant="outlined"
            type="submit"
            disabled={
              !globalState.providers.find((p) => p.shortid === providerShortid)
            }
          >
            Add new encounter.
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export const AddOrEditEncounter = (props: AddOrEditEncounterProps) => {
  const [encounter, setEncounter] = React.useState<EncounterData | null>(null);
  const [globalState] = gc.useGlobalContext();
  const endpoint = globalState.mportalEndpoint;
  const [queryFinished, setQueryFinished] = React.useState<boolean>(false);

  const pt = props.selectedPatientRecord;
  const getEncounter = async () => {
    setQueryFinished(false);
    if (!pt) {
      return;
    }
    const params = {
      patientId: pt.recordno,
      dateMinimum: datefns.format(props.date, "yyyy-MM-dd"),
      dateMaximum: datefns.format(props.date, "yyyy-MM-dd"),
    };
    const res = await endpoint.get<EncounterData[]>("office/encounters", {
      params,
    });
    if (res.data.length) {
      setEncounter(res.data[0]);
    } else {
      setEncounter(null);
    }
    setQueryFinished(true);
  };

  React.useEffect(() => {
    getEncounter();
  }, [props.selectedPatientRecord]);

  if (pt && !queryFinished) {
    <Card sx={{ width: "50%", minHeight: "15vh", p: 1, marginTop: 2 }}>
      <Typography variant="h6">
        {pt.lastname}, {pt.firstname} ({pt.recordno})
      </Typography>
      <CardContent>Loading...</CardContent>
    </Card>;
  }

  if (!encounter) {
    return <AddEncounter {...props} onEncounterUpdated={getEncounter} />;
  } else {
    return (
      <EncounterDetail
        encounter={encounter}
        patientRecord={pt}
        onEncounterDeleted={() => setEncounter(null)}
        onEncounterUpdated={getEncounter}
      />
    );
  }
};
