import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";

import { WaitingPatient } from "./api_types";
import * as datefns from "date-fns";

const roomListStyle = {
  //  width: "100%",
  minHeight: 50,
  maxHeight: 500,
  overflow: "auto",
};

type WaitingRoomProps = {
  waitingPatients: WaitingPatient[];
  roomId: string;
  roomName: string;
  minHeight: number;
  updateWaitingPatient: (patientId: number, update: WaitingPatient) => void;
  selectedPatientId: number;
  setSelectedPatientId: (patientId: number) => void;
};

export const WaitingRoom = ({
  waitingPatients,
  roomId,
  roomName,
  minHeight,
  updateWaitingPatient,
  selectedPatientId,
  setSelectedPatientId,
}: WaitingRoomProps) => {
  const [popoverAnchor, setPopoverAnchor] =
    React.useState<HTMLButtonElement | null>(null);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    patientId: number
  ) => {
    if (patientId !== selectedPatientId) {
      setSelectedPatientId(patientId);
    }
    setPopoverAnchor(event.currentTarget);
  };

  const handleClosePopover = () => setPopoverAnchor(null);

  const handleMovePatientTo = (destRoomId: string) => {
    const existing = waitingPatients.find(
      (wp) => wp.patientId === selectedPatientId
    );
    if (!existing) {
      console.log("ERRROR");
      return;
    }
    const update = { ...existing, status: destRoomId };
    updateWaitingPatient(selectedPatientId, update);
    handleClosePopover();
  };

  const showPopover = Boolean(popoverAnchor);

  const patientsInRoom = waitingPatients.filter((wp) => wp.status === roomId);

  const boxStyle = {
    ...roomListStyle,
    minHeight,
  };

  const MoveMenuItem = ({
    destRoomId,
    destRoomName,
  }: {
    destRoomId: string;
    destRoomName: string;
  }) => (
    <MenuItem
      onClick={() => handleMovePatientTo(destRoomId)}
      disabled={destRoomId === roomId}
    >
      {destRoomName}
    </MenuItem>
  );

  return (
    <div>
      <p>{roomName}</p>
      <Paper sx={boxStyle}>
        <List dense={true}>
          {patientsInRoom.map((wp: WaitingPatient) => {
            const sincestr = datefns.format(new Date(wp.since), "HH:mm:ss");
            return (
              <ListItem
                key={wp.patientId}
                selected={selectedPatientId === wp.patientId}
                onClick={() =>
                  wp.patientId != selectedPatientId &&
                  setSelectedPatientId(wp.patientId)
                }
              >
                <ListItemText>
                  {`${wp.ptLastname}, ${wp.ptFirstname} (${sincestr})`}
                </ListItemText>
                <Tooltip title="move to">
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ marginLeft: 1 }}
                  onClick={(event) => handleListItemClick(event, wp.patientId)}
                >
                  <TransferWithinAStationIcon />
                </Button>
                </Tooltip>
              </ListItem>
            );
          })}
        </List>
      </Paper>
      <Menu
        open={showPopover}
        anchorEl={popoverAnchor}
        onClose={handleClosePopover}
      >
        <MoveMenuItem destRoomId="WAITING_ROOM" destRoomName="Waiting Room" />
        <MoveMenuItem destRoomId="ROOM1" destRoomName="Room 1" />
        <MoveMenuItem destRoomId="ROOM2" destRoomName="Room 2" />
        <MoveMenuItem destRoomId="ROOM3" destRoomName="Room 3" />
        <MoveMenuItem destRoomId="ROOM4" destRoomName="Room 4" />
        <MoveMenuItem destRoomId="ROOM5" destRoomName="Room 5" />
        <MoveMenuItem destRoomId="GONE" destRoomName="Gone" />
      </Menu>
    </div>
  );
};
