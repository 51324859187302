import axios from "axios";

import { ApiCredentials } from "./api_credentials";

const MPORTAL_BACKEND_ENDPOINT = process.env.REACT_APP_MPORTAL_BACKEND_ENDPOINT;

const baseUrl = `${MPORTAL_BACKEND_ENDPOINT}/api/v1/login`;

export const login = async (
  username: string,
  password: string
): Promise<ApiCredentials> => {
  const reqData = {
    username,
    password,
  };
  const response = await axios.post<ApiCredentials>(baseUrl, reqData);
  return response.data;
};

interface RemainingValidityReseponse {
  remainingValidity: number;
}

export const getRemainingValidity = async (
  apiCredentials: ApiCredentials
): Promise<number> => {
  try {
    const response = await axios.get<RemainingValidityReseponse>(
      `${baseUrl}/check-token`,
      { headers: { Authorization: `bearer ${apiCredentials.token}` } }
    );
    return response.data.remainingValidity;
  } catch (error) {
    return 0;
  }
};
