import * as React from "react";
import { useParams, Outlet } from "react-router-dom";
import Container from "@mui/material/Container";

import * as gc from "../state/global_context";
import { PatientRecord } from "./api_types";
import { CurrentPatientContext } from "./current_patient_context";

type PatientHomeParams = {
  patientId: string;
};

export const PatientHome = () => {
  const params = useParams<PatientHomeParams>();
  const [patientRecord, setPatientRecord] =
    React.useState<PatientRecord | null>(null);
  const [globalState] = gc.useGlobalContext();
  const endpoint = globalState.mportalEndpoint;

  const getPatientRecord = async () => {
    const res = await endpoint.get<PatientRecord>(
      `office/patients/${params.patientId}`
    );
    setPatientRecord(res.data);
  };

  React.useEffect(() => {
    getPatientRecord();
  }, [params.patientId]);

  if (!patientRecord) {
    return (
      <Container maxWidth="xl" sx={{ p: 2 }}>
        Patient {params.patientId}
        <p>Loading...</p>
      </Container>
    );
  } else {
    return (
      <CurrentPatientContext.Provider value={patientRecord}>
        <Container maxWidth="xl" sx={{ p: 2 }}>
          <Outlet />
        </Container>
      </CurrentPatientContext.Provider>
    );
  }
};
